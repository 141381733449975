/* :: 14.0 Breadcrumb CSS */

.breadcrumb-area {
    position: relative;
    z-index: 1;
    height: 250px;
    margin-bottom: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .breadcrumb-area {
        height: 220px;
    }
}

@media only screen and (max-width: 767px) {
    .breadcrumb-area {
        height: 200px;
        margin-bottom: 0;
    }
}

.breadcrumb-area .breadcrumb-bg-curve {
    position: absolute;
    width: 80%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-size: cover;
    text-align: right !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .breadcrumb-area .breadcrumb-bg-curve {
        width: 90%;
    }
}

.breadcrumb-area .title {
    font-size: 60px;
    margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .breadcrumb-area .title {
        font-size: 42px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .breadcrumb-area .title {
        font-size: 36px;
    }
}

@media only screen and (max-width: 767px) {
    .breadcrumb-area .title {
        font-size: 30px;
    }
}

.breadcrumb-area .breadcumb--con .breadcrumb {
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
}

.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item,
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item > a,
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item.active {
    font-size: 18px;
    color: #303030;
    font-weight: normal;
    letter-spacing: normal;
}

@media only screen and (max-width: 767px) {
    .breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item,
    .breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item > a,
    .breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item.active {
        font-size: 14px;
    }
}

.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item:focus,
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item > a:focus,
.breadcrumb-area
    .breadcumb--con
    .breadcrumb
    .breadcrumb-item.active:focus
    .breadcrumb-area
    .breadcumb--con
    .breadcrumb
    .breadcrumb-item:hover,
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item > a:hover,
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item.active:hover {
    color: #1583e9;
}

.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item.active {
    color: #a6a6a6;
}

.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item.active:focus,
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item.active:hover {
    color: #a6a6a6;
}

.breadcrumb-area .breadcumb--con .breadcrumb .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.breadcrumb-area .breadcumb--con .breadcrumb .page-item:last-child .page-link {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    content: '\f105';
    font-family: 'FontAwesome';
}
