* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Arial';
    font-weight: 400;
    font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Arial';
    color: #303030;
    line-height: 1.4;
    font-weight: 600;
}

a,
a:active,
a:focus,
a:hover {
    font-family: 'Arial';
    color: #303030;
    text-decoration: none;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    outline: none;
    font-size: 16px;
    font-weight: 600;
}

li {
    list-style: none;
}

p {
    line-height: 2;
    color: #707070;
    font-size: 16px;
    font-weight: normal;
}

img {
    max-width: 100%;
    height: auto;
}

ul {
    margin: 0;
    padding: 0;
}

ul li {
    margin: 0;
    padding: 0;
}

.bg-overlay {
    position: relative;
    z-index: 1;
}

.bg-overlay::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5);
}

.bg-img {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.jarallax {
    position: relative;
    z-index: 0;
}
.jarallax > .jarallax-img {
    position: absolute;
    object-fit: cover;
    font-family: 'object-fit: cover';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.mfp-iframe-holder .mfp-close {
    top: 0;
    width: 30px;
    height: 30px;
    background-color: #1583e9;
    text-align: center;
    right: 0;
    padding-right: 0;
    line-height: 32px;
}

.bg-transparent {
    background-color: transparent !important;
}

.bg-gray {
    background-color: #e7f2fd !important;
}

textarea:focus,
input:focus {
    outline: none;
}
