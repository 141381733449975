@font-face {
    font-family: 'ElegantIcons';
    src: url('fonts/ElegantIcons.eot');
    src: url('fonts/ElegantIcons.eot?#iefix') format('embedded-opentype'),
        url('fonts/ElegantIcons.woff') format('woff'),
        url('fonts/ElegantIcons.ttf') format('truetype'),
        url('fonts/ElegantIcons.svg#ElegantIcons') format('svg');
    font-weight: normal;
    font-style: normal;
}
.language-selector {
    border: 0;
    background-color: transparent;
    margin-left: 1px;
    outline: 0;
}
.language-container {
    padding-left: 10px;
    height: 38px;
    line-height: 38px;
    color: #1583e9;
}

@media only screen and (max-width: 991px) {
    .language-container {
        background-color: #f2f4f8;
    }
}
/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
    font-family: 'ElegantIcons';
    content: attr(data-icon);
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.page-enter {
    opacity: 0;
    transform: scale(1.1);
}

.page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
}

.page-exit {
    opacity: 1;
    transform: scale(1);
}

.page-exit-active {
    opacity: 0;
    transform: scale(1, 1);
    transition: opacity 300ms, transform 300ms;
}

/* Use the following CSS code if you want to have a class per icon */
/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="your-class-prefix"] {
*/
.arrow_up,
.arrow_down,
.arrow_left,
.arrow_right,
.arrow_left-up,
.arrow_right-up,
.arrow_right-down,
.arrow_left-down,
.arrow-up-down,
.arrow_up-down_alt,
.arrow_left-right_alt,
.arrow_left-right,
.arrow_expand_alt2,
.arrow_expand_alt,
.arrow_condense,
.arrow_expand,
.arrow_move,
.arrow_carrot-up,
.arrow_carrot-down,
.arrow_carrot-left,
.arrow_carrot-right,
.arrow_carrot-2up,
.arrow_carrot-2down,
.arrow_carrot-2left,
.arrow_carrot-2right,
.arrow_carrot-up_alt2,
.arrow_carrot-down_alt2,
.arrow_carrot-left_alt2,
.arrow_carrot-right_alt2,
.arrow_carrot-2up_alt2,
.arrow_carrot-2down_alt2,
.arrow_carrot-2left_alt2,
.arrow_carrot-2right_alt2,
.arrow_triangle-up,
.arrow_triangle-down,
.arrow_triangle-left,
.arrow_triangle-right,
.arrow_triangle-up_alt2,
.arrow_triangle-down_alt2,
.arrow_triangle-left_alt2,
.arrow_triangle-right_alt2,
.arrow_back,
.icon_minus-06,
.icon_plus,
.icon_close,
.icon_check,
.icon_minus_alt2,
.icon_plus_alt2,
.icon_close_alt2,
.icon_check_alt2,
.icon_zoom-out_alt,
.icon_zoom-in_alt,
.icon_search,
.icon_box-empty,
.icon_box-selected,
.icon_minus-box,
.icon_plus-box,
.icon_box-checked,
.icon_circle-empty,
.icon_circle-slelected,
.icon_stop_alt2,
.icon_stop,
.icon_pause_alt2,
.icon_pause,
.icon_menu,
.icon_menu-square_alt2,
.icon_menu-circle_alt2,
.icon_ul,
.icon_ol,
.icon_adjust-horiz,
.icon_adjust-vert,
.icon_document_alt,
.icon_documents_alt,
.icon_pencil,
.icon_pencil-edit_alt,
.icon_pencil-edit,
.icon_folder-alt,
.icon_folder-open_alt,
.icon_folder-add_alt,
.icon_info_alt,
.icon_error-oct_alt,
.icon_error-circle_alt,
.icon_error-triangle_alt,
.icon_question_alt2,
.icon_question,
.icon_comment_alt,
.icon_chat_alt,
.icon_vol-mute_alt,
.icon_volume-low_alt,
.icon_volume-high_alt,
.icon_quotations,
.icon_quotations_alt2,
.icon_clock_alt,
.icon_lock_alt,
.icon_lock-open_alt,
.icon_key_alt,
.icon_cloud_alt,
.icon_cloud-upload_alt,
.icon_cloud-download_alt,
.icon_image,
.icon_images,
.icon_lightbulb_alt,
.icon_gift_alt,
.icon_house_alt,
.icon_genius,
.icon_mobile,
.icon_tablet,
.icon_laptop,
.icon_desktop,
.icon_camera_alt,
.icon_mail_alt,
.icon_cone_alt,
.icon_ribbon_alt,
.icon_bag_alt,
.icon_creditcard,
.icon_cart_alt,
.icon_paperclip,
.icon_tag_alt,
.icon_tags_alt,
.icon_trash_alt,
.icon_cursor_alt,
.icon_mic_alt,
.icon_compass_alt,
.icon_pin_alt,
.icon_pushpin_alt,
.icon_map_alt,
.icon_drawer_alt,
.icon_toolbox_alt,
.icon_book_alt,
.icon_calendar,
.icon_film,
.icon_table,
.icon_contacts_alt,
.icon_headphones,
.icon_lifesaver,
.icon_piechart,
.icon_refresh,
.icon_link_alt,
.icon_link,
.icon_loading,
.icon_blocked,
.icon_archive_alt,
.icon_heart_alt,
.icon_star_alt,
.icon_star-half_alt,
.icon_star,
.icon_star-half,
.icon_tools,
.icon_tool,
.icon_cog,
.icon_cogs,
.arrow_up_alt,
.arrow_down_alt,
.arrow_left_alt,
.arrow_right_alt,
.arrow_left-up_alt,
.arrow_right-up_alt,
.arrow_right-down_alt,
.arrow_left-down_alt,
.arrow_condense_alt,
.arrow_expand_alt3,
.arrow_carrot_up_alt,
.arrow_carrot-down_alt,
.arrow_carrot-left_alt,
.arrow_carrot-right_alt,
.arrow_carrot-2up_alt,
.arrow_carrot-2dwnn_alt,
.arrow_carrot-2left_alt,
.arrow_carrot-2right_alt,
.arrow_triangle-up_alt,
.arrow_triangle-down_alt,
.arrow_triangle-left_alt,
.arrow_triangle-right_alt,
.icon_minus_alt,
.icon_plus_alt,
.icon_close_alt,
.icon_check_alt,
.icon_zoom-out,
.icon_zoom-in,
.icon_stop_alt,
.icon_menu-square_alt,
.icon_menu-circle_alt,
.icon_document,
.icon_documents,
.icon_pencil_alt,
.icon_folder,
.icon_folder-open,
.icon_folder-add,
.icon_folder_upload,
.icon_folder_download,
.icon_info,
.icon_error-circle,
.icon_error-oct,
.icon_error-triangle,
.icon_question_alt,
.icon_comment,
.icon_chat,
.icon_vol-mute,
.icon_volume-low,
.icon_volume-high,
.icon_quotations_alt,
.icon_clock,
.icon_lock,
.icon_lock-open,
.icon_key,
.icon_cloud,
.icon_cloud-upload,
.icon_cloud-download,
.icon_lightbulb,
.icon_gift,
.icon_house,
.icon_camera,
.icon_mail,
.icon_cone,
.icon_ribbon,
.icon_bag,
.icon_cart,
.icon_tag,
.icon_tags,
.icon_trash,
.icon_cursor,
.icon_mic,
.icon_compass,
.icon_pin,
.icon_pushpin,
.icon_map,
.icon_drawer,
.icon_toolbox,
.icon_book,
.icon_contacts,
.icon_archive,
.icon_heart,
.icon_profile,
.icon_group,
.icon_grid-2x2,
.icon_grid-3x3,
.icon_music,
.icon_pause_alt,
.icon_phone,
.icon_upload,
.icon_download,
.social_facebook,
.social_twitter,
.social_pinterest,
.social_googleplus,
.social_tumblr,
.social_tumbleupon,
.social_wordpress,
.social_instagram,
.social_dribbble,
.social_vimeo,
.social_linkedin,
.social_rss,
.social_deviantart,
.social_share,
.social_myspace,
.social_skype,
.social_youtube,
.social_picassa,
.social_googledrive,
.social_flickr,
.social_blogger,
.social_spotify,
.social_delicious,
.social_facebook_circle,
.social_twitter_circle,
.social_pinterest_circle,
.social_googleplus_circle,
.social_tumblr_circle,
.social_stumbleupon_circle,
.social_wordpress_circle,
.social_instagram_circle,
.social_dribbble_circle,
.social_vimeo_circle,
.social_linkedin_circle,
.social_rss_circle,
.social_deviantart_circle,
.social_share_circle,
.social_myspace_circle,
.social_skype_circle,
.social_youtube_circle,
.social_picassa_circle,
.social_googledrive_alt2,
.social_flickr_circle,
.social_blogger_circle,
.social_spotify_circle,
.social_delicious_circle,
.social_facebook_square,
.social_twitter_square,
.social_pinterest_square,
.social_googleplus_square,
.social_tumblr_square,
.social_stumbleupon_square,
.social_wordpress_square,
.social_instagram_square,
.social_dribbble_square,
.social_vimeo_square,
.social_linkedin_square,
.social_rss_square,
.social_deviantart_square,
.social_share_square,
.social_myspace_square,
.social_skype_square,
.social_youtube_square,
.social_picassa_square,
.social_googledrive_square,
.social_flickr_square,
.social_blogger_square,
.social_spotify_square,
.social_delicious_square,
.icon_printer,
.icon_calulator,
.icon_building,
.icon_floppy,
.icon_drive,
.icon_search-2,
.icon_id,
.icon_id-2,
.icon_puzzle,
.icon_like,
.icon_dislike,
.icon_mug,
.icon_currency,
.icon_wallet,
.icon_pens,
.icon_easel,
.icon_flowchart,
.icon_datareport,
.icon_briefcase,
.icon_shield,
.icon_percent,
.icon_globe,
.icon_globe-2,
.icon_target,
.icon_hourglass,
.icon_balance,
.icon_rook,
.icon_printer-alt,
.icon_calculator_alt,
.icon_building_alt,
.icon_floppy_alt,
.icon_drive_alt,
.icon_search_alt,
.icon_id_alt,
.icon_id-2_alt,
.icon_puzzle_alt,
.icon_like_alt,
.icon_dislike_alt,
.icon_mug_alt,
.icon_currency_alt,
.icon_wallet_alt,
.icon_pens_alt,
.icon_easel_alt,
.icon_flowchart_alt,
.icon_datareport_alt,
.icon_briefcase_alt,
.icon_shield_alt,
.icon_percent_alt,
.icon_globe_alt,
.icon_clipboard {
    font-family: 'ElegantIcons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}
.arrow_up:before {
    content: '\21';
}
.arrow_down:before {
    content: '\22';
}
.arrow_left:before {
    content: '\23';
}
.arrow_right:before {
    content: '\24';
}
.arrow_left-up:before {
    content: '\25';
}
.arrow_right-up:before {
    content: '\26';
}
.arrow_right-down:before {
    content: '\27';
}
.arrow_left-down:before {
    content: '\28';
}
.arrow-up-down:before {
    content: '\29';
}
.arrow_up-down_alt:before {
    content: '\2a';
}
.arrow_left-right_alt:before {
    content: '\2b';
}
.arrow_left-right:before {
    content: '\2c';
}
.arrow_expand_alt2:before {
    content: '\2d';
}
.arrow_expand_alt:before {
    content: '\2e';
}
.arrow_condense:before {
    content: '\2f';
}
.arrow_expand:before {
    content: '\30';
}
.arrow_move:before {
    content: '\31';
}
.arrow_carrot-up:before {
    content: '\32';
}
.arrow_carrot-down:before {
    content: '\33';
}
.arrow_carrot-left:before {
    content: '\34';
}
.arrow_carrot-right:before {
    content: '\35';
}
.arrow_carrot-2up:before {
    content: '\36';
}
.arrow_carrot-2down:before {
    content: '\37';
}
.arrow_carrot-2left:before {
    content: '\38';
}
.arrow_carrot-2right:before {
    content: '\39';
}
.arrow_carrot-up_alt2:before {
    content: '\3a';
}
.arrow_carrot-down_alt2:before {
    content: '\3b';
}
.arrow_carrot-left_alt2:before {
    content: '\3c';
}
.arrow_carrot-right_alt2:before {
    content: '\3d';
}
.arrow_carrot-2up_alt2:before {
    content: '\3e';
}
.arrow_carrot-2down_alt2:before {
    content: '\3f';
}
.arrow_carrot-2left_alt2:before {
    content: '\40';
}
.arrow_carrot-2right_alt2:before {
    content: '\41';
}
.arrow_triangle-up:before {
    content: '\42';
}
.arrow_triangle-down:before {
    content: '\43';
}
.arrow_triangle-left:before {
    content: '\44';
}
.arrow_triangle-right:before {
    content: '\45';
}
.arrow_triangle-up_alt2:before {
    content: '\46';
}
.arrow_triangle-down_alt2:before {
    content: '\47';
}
.arrow_triangle-left_alt2:before {
    content: '\48';
}
.arrow_triangle-right_alt2:before {
    content: '\49';
}
.arrow_back:before {
    content: '\4a';
}
.icon_minus-06:before {
    content: '\4b';
}
.icon_plus:before {
    content: '\4c';
}
.icon_close:before {
    content: '\4d';
}
.icon_check:before {
    content: '\4e';
}
.icon_minus_alt2:before {
    content: '\4f';
}
.icon_plus_alt2:before {
    content: '\50';
}
.icon_close_alt2:before {
    content: '\51';
}
.icon_check_alt2:before {
    content: '\52';
}
.icon_zoom-out_alt:before {
    content: '\53';
}
.icon_zoom-in_alt:before {
    content: '\54';
}
.icon_search:before {
    content: '\55';
}
.icon_box-empty:before {
    content: '\56';
}
.icon_box-selected:before {
    content: '\57';
}
.icon_minus-box:before {
    content: '\58';
}
.icon_plus-box:before {
    content: '\59';
}
.icon_box-checked:before {
    content: '\5a';
}
.icon_circle-empty:before {
    content: '\5b';
}
.icon_circle-slelected:before {
    content: '\5c';
}
.icon_stop_alt2:before {
    content: '\5d';
}
.icon_stop:before {
    content: '\5e';
}
.icon_pause_alt2:before {
    content: '\5f';
}
.icon_pause:before {
    content: '\60';
}
.icon_menu:before {
    content: '\61';
}
.icon_menu-square_alt2:before {
    content: '\62';
}
.icon_menu-circle_alt2:before {
    content: '\63';
}
.icon_ul:before {
    content: '\64';
}
.icon_ol:before {
    content: '\65';
}
.icon_adjust-horiz:before {
    content: '\66';
}
.icon_adjust-vert:before {
    content: '\67';
}
.icon_document_alt:before {
    content: '\68';
}
.icon_documents_alt:before {
    content: '\69';
}
.icon_pencil:before {
    content: '\6a';
}
.icon_pencil-edit_alt:before {
    content: '\6b';
}
.icon_pencil-edit:before {
    content: '\6c';
}
.icon_folder-alt:before {
    content: '\6d';
}
.icon_folder-open_alt:before {
    content: '\6e';
}
.icon_folder-add_alt:before {
    content: '\6f';
}
.icon_info_alt:before {
    content: '\70';
}
.icon_error-oct_alt:before {
    content: '\71';
}
.icon_error-circle_alt:before {
    content: '\72';
}
.icon_error-triangle_alt:before {
    content: '\73';
}
.icon_question_alt2:before {
    content: '\74';
}
.icon_question:before {
    content: '\75';
}
.icon_comment_alt:before {
    content: '\76';
}
.icon_chat_alt:before {
    content: '\77';
}
.icon_vol-mute_alt:before {
    content: '\78';
}
.icon_volume-low_alt:before {
    content: '\79';
}
.icon_volume-high_alt:before {
    content: '\7a';
}
.icon_quotations:before {
    content: '\7b';
}
.icon_quotations_alt2:before {
    content: '\7c';
}
.icon_clock_alt:before {
    content: '\7d';
}
.icon_lock_alt:before {
    content: '\7e';
}
.icon_lock-open_alt:before {
    content: '\e000';
}
.icon_key_alt:before {
    content: '\e001';
}
.icon_cloud_alt:before {
    content: '\e002';
}
.icon_cloud-upload_alt:before {
    content: '\e003';
}
.icon_cloud-download_alt:before {
    content: '\e004';
}
.icon_image:before {
    content: '\e005';
}
.icon_images:before {
    content: '\e006';
}
.icon_lightbulb_alt:before {
    content: '\e007';
}
.icon_gift_alt:before {
    content: '\e008';
}
.icon_house_alt:before {
    content: '\e009';
}
.icon_genius:before {
    content: '\e00a';
}
.icon_mobile:before {
    content: '\e00b';
}
.icon_tablet:before {
    content: '\e00c';
}
.icon_laptop:before {
    content: '\e00d';
}
.icon_desktop:before {
    content: '\e00e';
}
.icon_camera_alt:before {
    content: '\e00f';
}
.icon_mail_alt:before {
    content: '\e010';
}
.icon_cone_alt:before {
    content: '\e011';
}
.icon_ribbon_alt:before {
    content: '\e012';
}
.icon_bag_alt:before {
    content: '\e013';
}
.icon_creditcard:before {
    content: '\e014';
}
.icon_cart_alt:before {
    content: '\e015';
}
.icon_paperclip:before {
    content: '\e016';
}
.icon_tag_alt:before {
    content: '\e017';
}
.icon_tags_alt:before {
    content: '\e018';
}
.icon_trash_alt:before {
    content: '\e019';
}
.icon_cursor_alt:before {
    content: '\e01a';
}
.icon_mic_alt:before {
    content: '\e01b';
}
.icon_compass_alt:before {
    content: '\e01c';
}
.icon_pin_alt:before {
    content: '\e01d';
}
.icon_pushpin_alt:before {
    content: '\e01e';
}
.icon_map_alt:before {
    content: '\e01f';
}
.icon_drawer_alt:before {
    content: '\e020';
}
.icon_toolbox_alt:before {
    content: '\e021';
}
.icon_book_alt:before {
    content: '\e022';
}
.icon_calendar:before {
    content: '\e023';
}
.icon_film:before {
    content: '\e024';
}
.icon_table:before {
    content: '\e025';
}
.icon_contacts_alt:before {
    content: '\e026';
}
.icon_headphones:before {
    content: '\e027';
}
.icon_lifesaver:before {
    content: '\e028';
}
.icon_piechart:before {
    content: '\e029';
}
.icon_refresh:before {
    content: '\e02a';
}
.icon_link_alt:before {
    content: '\e02b';
}
.icon_link:before {
    content: '\e02c';
}
.icon_loading:before {
    content: '\e02d';
}
.icon_blocked:before {
    content: '\e02e';
}
.icon_archive_alt:before {
    content: '\e02f';
}
.icon_heart_alt:before {
    content: '\e030';
}
.icon_star_alt:before {
    content: '\e031';
}
.icon_star-half_alt:before {
    content: '\e032';
}
.icon_star:before {
    content: '\e033';
}
.icon_star-half:before {
    content: '\e034';
}
.icon_tools:before {
    content: '\e035';
}
.icon_tool:before {
    content: '\e036';
}
.icon_cog:before {
    content: '\e037';
}
.icon_cogs:before {
    content: '\e038';
}
.arrow_up_alt:before {
    content: '\e039';
}
.arrow_down_alt:before {
    content: '\e03a';
}
.arrow_left_alt:before {
    content: '\e03b';
}
.arrow_right_alt:before {
    content: '\e03c';
}
.arrow_left-up_alt:before {
    content: '\e03d';
}
.arrow_right-up_alt:before {
    content: '\e03e';
}
.arrow_right-down_alt:before {
    content: '\e03f';
}
.arrow_left-down_alt:before {
    content: '\e040';
}
.arrow_condense_alt:before {
    content: '\e041';
}
.arrow_expand_alt3:before {
    content: '\e042';
}
.arrow_carrot_up_alt:before {
    content: '\e043';
}
.arrow_carrot-down_alt:before {
    content: '\e044';
}
.arrow_carrot-left_alt:before {
    content: '\e045';
}
.arrow_carrot-right_alt:before {
    content: '\e046';
}
.arrow_carrot-2up_alt:before {
    content: '\e047';
}
.arrow_carrot-2dwnn_alt:before {
    content: '\e048';
}
.arrow_carrot-2left_alt:before {
    content: '\e049';
}
.arrow_carrot-2right_alt:before {
    content: '\e04a';
}
.arrow_triangle-up_alt:before {
    content: '\e04b';
}
.arrow_triangle-down_alt:before {
    content: '\e04c';
}
.arrow_triangle-left_alt:before {
    content: '\e04d';
}
.arrow_triangle-right_alt:before {
    content: '\e04e';
}
.icon_minus_alt:before {
    content: '\e04f';
}
.icon_plus_alt:before {
    content: '\e050';
}
.icon_close_alt:before {
    content: '\e051';
}
.icon_check_alt:before {
    content: '\e052';
}
.icon_zoom-out:before {
    content: '\e053';
}
.icon_zoom-in:before {
    content: '\e054';
}
.icon_stop_alt:before {
    content: '\e055';
}
.icon_menu-square_alt:before {
    content: '\e056';
}
.icon_menu-circle_alt:before {
    content: '\e057';
}
.icon_document:before {
    content: '\e058';
}
.icon_documents:before {
    content: '\e059';
}
.icon_pencil_alt:before {
    content: '\e05a';
}
.icon_folder:before {
    content: '\e05b';
}
.icon_folder-open:before {
    content: '\e05c';
}
.icon_folder-add:before {
    content: '\e05d';
}
.icon_folder_upload:before {
    content: '\e05e';
}
.icon_folder_download:before {
    content: '\e05f';
}
.icon_info:before {
    content: '\e060';
}
.icon_error-circle:before {
    content: '\e061';
}
.icon_error-oct:before {
    content: '\e062';
}
.icon_error-triangle:before {
    content: '\e063';
}
.icon_question_alt:before {
    content: '\e064';
}
.icon_comment:before {
    content: '\e065';
}
.icon_chat:before {
    content: '\e066';
}
.icon_vol-mute:before {
    content: '\e067';
}
.icon_volume-low:before {
    content: '\e068';
}
.icon_volume-high:before {
    content: '\e069';
}
.icon_quotations_alt:before {
    content: '\e06a';
}
.icon_clock:before {
    content: '\e06b';
}
.icon_lock:before {
    content: '\e06c';
}
.icon_lock-open:before {
    content: '\e06d';
}
.icon_key:before {
    content: '\e06e';
}
.icon_cloud:before {
    content: '\e06f';
}
.icon_cloud-upload:before {
    content: '\e070';
}
.icon_cloud-download:before {
    content: '\e071';
}
.icon_lightbulb:before {
    content: '\e072';
}
.icon_gift:before {
    content: '\e073';
}
.icon_house:before {
    content: '\e074';
}
.icon_camera:before {
    content: '\e075';
}
.icon_mail:before {
    content: '\e076';
}
.icon_cone:before {
    content: '\e077';
}
.icon_ribbon:before {
    content: '\e078';
}
.icon_bag:before {
    content: '\e079';
}
.icon_cart:before {
    content: '\e07a';
}
.icon_tag:before {
    content: '\e07b';
}
.icon_tags:before {
    content: '\e07c';
}
.icon_trash:before {
    content: '\e07d';
}
.icon_cursor:before {
    content: '\e07e';
}
.icon_mic:before {
    content: '\e07f';
}
.icon_compass:before {
    content: '\e080';
}
.icon_pin:before {
    content: '\e081';
}
.icon_pushpin:before {
    content: '\e082';
}
.icon_map:before {
    content: '\e083';
}
.icon_drawer:before {
    content: '\e084';
}
.icon_toolbox:before {
    content: '\e085';
}
.icon_book:before {
    content: '\e086';
}
.icon_contacts:before {
    content: '\e087';
}
.icon_archive:before {
    content: '\e088';
}
.icon_heart:before {
    content: '\e089';
}
.icon_profile:before {
    content: '\e08a';
}
.icon_group:before {
    content: '\e08b';
}
.icon_grid-2x2:before {
    content: '\e08c';
}
.icon_grid-3x3:before {
    content: '\e08d';
}
.icon_music:before {
    content: '\e08e';
}
.icon_pause_alt:before {
    content: '\e08f';
}
.icon_phone:before {
    content: '\e090';
}
.icon_upload:before {
    content: '\e091';
}
.icon_download:before {
    content: '\e092';
}
.social_facebook:before {
    content: '\e093';
}
.social_twitter:before {
    content: '\e094';
}
.social_pinterest:before {
    content: '\e095';
}
.social_googleplus:before {
    content: '\e096';
}
.social_tumblr:before {
    content: '\e097';
}
.social_tumbleupon:before {
    content: '\e098';
}
.social_wordpress:before {
    content: '\e099';
}
.social_instagram:before {
    content: '\e09a';
}
.social_dribbble:before {
    content: '\e09b';
}
.social_vimeo:before {
    content: '\e09c';
}
.social_linkedin:before {
    content: '\e09d';
}
.social_rss:before {
    content: '\e09e';
}
.social_deviantart:before {
    content: '\e09f';
}
.social_share:before {
    content: '\e0a0';
}
.social_myspace:before {
    content: '\e0a1';
}
.social_skype:before {
    content: '\e0a2';
}
.social_youtube:before {
    content: '\e0a3';
}
.social_picassa:before {
    content: '\e0a4';
}
.social_googledrive:before {
    content: '\e0a5';
}
.social_flickr:before {
    content: '\e0a6';
}
.social_blogger:before {
    content: '\e0a7';
}
.social_spotify:before {
    content: '\e0a8';
}
.social_delicious:before {
    content: '\e0a9';
}
.social_facebook_circle:before {
    content: '\e0aa';
}
.social_twitter_circle:before {
    content: '\e0ab';
}
.social_pinterest_circle:before {
    content: '\e0ac';
}
.social_googleplus_circle:before {
    content: '\e0ad';
}
.social_tumblr_circle:before {
    content: '\e0ae';
}
.social_stumbleupon_circle:before {
    content: '\e0af';
}
.social_wordpress_circle:before {
    content: '\e0b0';
}
.social_instagram_circle:before {
    content: '\e0b1';
}
.social_dribbble_circle:before {
    content: '\e0b2';
}
.social_vimeo_circle:before {
    content: '\e0b3';
}
.social_linkedin_circle:before {
    content: '\e0b4';
}
.social_rss_circle:before {
    content: '\e0b5';
}
.social_deviantart_circle:before {
    content: '\e0b6';
}
.social_share_circle:before {
    content: '\e0b7';
}
.social_myspace_circle:before {
    content: '\e0b8';
}
.social_skype_circle:before {
    content: '\e0b9';
}
.social_youtube_circle:before {
    content: '\e0ba';
}
.social_picassa_circle:before {
    content: '\e0bb';
}
.social_googledrive_alt2:before {
    content: '\e0bc';
}
.social_flickr_circle:before {
    content: '\e0bd';
}
.social_blogger_circle:before {
    content: '\e0be';
}
.social_spotify_circle:before {
    content: '\e0bf';
}
.social_delicious_circle:before {
    content: '\e0c0';
}
.social_facebook_square:before {
    content: '\e0c1';
}
.social_twitter_square:before {
    content: '\e0c2';
}
.social_pinterest_square:before {
    content: '\e0c3';
}
.social_googleplus_square:before {
    content: '\e0c4';
}
.social_tumblr_square:before {
    content: '\e0c5';
}
.social_stumbleupon_square:before {
    content: '\e0c6';
}
.social_wordpress_square:before {
    content: '\e0c7';
}
.social_instagram_square:before {
    content: '\e0c8';
}
.social_dribbble_square:before {
    content: '\e0c9';
}
.social_vimeo_square:before {
    content: '\e0ca';
}
.social_linkedin_square:before {
    content: '\e0cb';
}
.social_rss_square:before {
    content: '\e0cc';
}
.social_deviantart_square:before {
    content: '\e0cd';
}
.social_share_square:before {
    content: '\e0ce';
}
.social_myspace_square:before {
    content: '\e0cf';
}
.social_skype_square:before {
    content: '\e0d0';
}
.social_youtube_square:before {
    content: '\e0d1';
}
.social_picassa_square:before {
    content: '\e0d2';
}
.social_googledrive_square:before {
    content: '\e0d3';
}
.social_flickr_square:before {
    content: '\e0d4';
}
.social_blogger_square:before {
    content: '\e0d5';
}
.social_spotify_square:before {
    content: '\e0d6';
}
.social_delicious_square:before {
    content: '\e0d7';
}
.icon_printer:before {
    content: '\e103';
}
.icon_calulator:before {
    content: '\e0ee';
}
.icon_building:before {
    content: '\e0ef';
}
.icon_floppy:before {
    content: '\e0e8';
}
.icon_drive:before {
    content: '\e0ea';
}
.icon_search-2:before {
    content: '\e101';
}
.icon_id:before {
    content: '\e107';
}
.icon_id-2:before {
    content: '\e108';
}
.icon_puzzle:before {
    content: '\e102';
}
.icon_like:before {
    content: '\e106';
}
.icon_dislike:before {
    content: '\e0eb';
}
.icon_mug:before {
    content: '\e105';
}
.icon_currency:before {
    content: '\e0ed';
}
.icon_wallet:before {
    content: '\e100';
}
.icon_pens:before {
    content: '\e104';
}
.icon_easel:before {
    content: '\e0e9';
}
.icon_flowchart:before {
    content: '\e109';
}
.icon_datareport:before {
    content: '\e0ec';
}
.icon_briefcase:before {
    content: '\e0fe';
}
.icon_shield:before {
    content: '\e0f6';
}
.icon_percent:before {
    content: '\e0fb';
}
.icon_globe:before {
    content: '\e0e2';
}
.icon_globe-2:before {
    content: '\e0e3';
}
.icon_target:before {
    content: '\e0f5';
}
.icon_hourglass:before {
    content: '\e0e1';
}
.icon_balance:before {
    content: '\e0ff';
}
.icon_rook:before {
    content: '\e0f8';
}
.icon_printer-alt:before {
    content: '\e0fa';
}
.icon_calculator_alt:before {
    content: '\e0e7';
}
.icon_building_alt:before {
    content: '\e0fd';
}
.icon_floppy_alt:before {
    content: '\e0e4';
}
.icon_drive_alt:before {
    content: '\e0e5';
}
.icon_search_alt:before {
    content: '\e0f7';
}
.icon_id_alt:before {
    content: '\e0e0';
}
.icon_id-2_alt:before {
    content: '\e0fc';
}
.icon_puzzle_alt:before {
    content: '\e0f9';
}
.icon_like_alt:before {
    content: '\e0dd';
}
.icon_dislike_alt:before {
    content: '\e0f1';
}
.icon_mug_alt:before {
    content: '\e0dc';
}
.icon_currency_alt:before {
    content: '\e0f3';
}
.icon_wallet_alt:before {
    content: '\e0d8';
}
.icon_pens_alt:before {
    content: '\e0db';
}
.icon_easel_alt:before {
    content: '\e0f0';
}
.icon_flowchart_alt:before {
    content: '\e0df';
}
.icon_datareport_alt:before {
    content: '\e0f2';
}
.icon_briefcase_alt:before {
    content: '\e0f4';
}
.icon_shield_alt:before {
    content: '\e0d9';
}
.icon_percent_alt:before {
    content: '\e0da';
}
.icon_globe_alt:before {
    content: '\e0de';
}
.icon_clipboard:before {
    content: '\e0e6';
}

.glyph {
    float: left;
    text-align: center;
    padding: 0.75em;
    margin: 0.4em 1.5em 0.75em 0;
    width: 6em;
    text-shadow: none;
}
.glyph_big {
    font-size: 128px;
    color: #59c5dc;
    float: left;
    margin-right: 20px;
}

.glyph div {
    padding-bottom: 10px;
}

.glyph input {
    font-family: consolas, monospace;
    font-size: 12px;
    width: 100%;
    text-align: center;
    border: 0;
    box-shadow: 0 0 0 1px #ccc;
    padding: 0.2em;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}
.centered {
    margin-left: auto;
    margin-right: auto;
}
.glyph .fs1 {
    font-size: 2em;
}

/* :: 1.0 Reboot CSS */
* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Arial';
    font-weight: 400;
    font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Arial';
    color: #303030;
    line-height: 1.4;
    font-weight: 600;
}

a,
a:active,
a:focus,
a:hover {
    font-family: 'Arial';
    color: #303030;
    text-decoration: none;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    outline: none;
    font-size: 16px;
    font-weight: 600;
}

li {
    list-style: none;
}

p {
    line-height: 2;
    color: #707070;
    font-size: 16px;
    font-weight: normal;
}

img {
    max-width: 100%;
    height: auto;
}

ul {
    margin: 0;
    padding: 0;
}
ul li {
    margin: 0;
    padding: 0;
}

.bg-overlay {
    position: relative;
    z-index: 1;
}
.bg-overlay::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5);
}

.bg-img {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.jarallax {
    position: relative;
    z-index: 0;
}
.jarallax > .jarallax-img {
    position: absolute;
    object-fit: cover;
    font-family: 'object-fit: cover';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.mfp-iframe-holder .mfp-close {
    top: 0;
    width: 30px;
    height: 30px;
    background-color: #1583e9;
    text-align: center;
    right: 0;
    padding-right: 0;
    line-height: 32px;
}

.bg-transparent {
    background-color: transparent !important;
}

.bg-gray {
    background-color: #e7f2fd !important;
}

textarea:focus,
input:focus {
    outline: none;
}

/* :: 2.0 Spacing CSS */
.mt-15 {
    margin-top: 15px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-70 {
    margin-top: 70px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-150 {
    margin-top: 150px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-50 {
    margin-right: 50px;
}

.mr-100 {
    margin-right: 100px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-70 {
    margin-bottom: 70px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mb-100 {
    margin-bottom: 100px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-50 {
    margin-left: 50px;
}

.ml-100 {
    margin-left: 100px;
}

.pt-50 {
    padding-top: 50px !important;
}

.section-padding-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.section-padding-0-80 {
    padding-top: 0;
    padding-bottom: 80px;
}

.section-padding-80-0 {
    padding-top: 80px;
    padding-bottom: 0;
}

/* :: 3.0 Preloader CSS */
#preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: #1583e9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.wrapper {
    margin: auto;
    display: block;
}

.cssload-loader {
    width: 49px;
    height: 49px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    background: #ffffff;
}

.cssload-loader,
.cssload-loader:after,
.cssload-loader:before {
    animation: 1.15s infinite ease-in-out;
    -o-animation: 1.15s infinite ease-in-out;
    -ms-animation: 1.15s infinite ease-in-out;
    -webkit-animation: 1.15s infinite ease-in-out;
    -moz-animation: 1.15s infinite ease-in-out;
}

.cssload-loader:after,
.cssload-loader:before {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
}

.cssload-loader {
    animation-name: cssload-loader;
    -o-animation-name: cssload-loader;
    -ms-animation-name: cssload-loader;
    -webkit-animation-name: cssload-loader;
    -moz-animation-name: cssload-loader;
}

@keyframes cssload-loader {
    from {
        transform: scale(0);
        opacity: 1;
    }
    to {
        transform: scale(1);
        opacity: 0;
    }
}
@-o-keyframes cssload-loader {
    from {
        -o-transform: scale(0);
        opacity: 1;
    }
    to {
        -o-transform: scale(1);
        opacity: 0;
    }
}
@-ms-keyframes cssload-loader {
    from {
        -ms-transform: scale(0);
        opacity: 1;
    }
    to {
        -ms-transform: scale(1);
        opacity: 0;
    }
}
@-webkit-keyframes cssload-loader {
    from {
        -webkit-transform: scale(0);
        opacity: 1;
    }
    to {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}
@-moz-keyframes cssload-loader {
    from {
        -moz-transform: scale(0);
        opacity: 1;
    }
    to {
        -moz-transform: scale(1);
        opacity: 0;
    }
}
/* :: 4.0 Heading CSS */
.section-heading {
    position: relative;
    z-index: 1;
    margin-bottom: 70px;
}
.section-heading h2 {
    font-size: 48px;
    margin-bottom: 0;
    line-height: 1.1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-heading h2 {
        font-size: 36px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-heading h2 {
        font-size: 42px;
    }
}
@media only screen and (max-width: 767px) {
    .section-heading h2 {
        font-size: 30px;
    }
}
.section-heading p {
    margin-top: 15px;
    font-size: 18px;
    margin-bottom: 0;
    color: #a6a6a6;
}

/* :: 5.0 Backtotop CSS */
#scrollUp {
    bottom: 50px;
    font-size: 30px;
    line-height: 45px;
    right: 50px;
    width: 50px;
    background-color: #1583e9;
    color: #ffffff;
    text-align: center;
    height: 50px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    border-radius: 50%;
    -webkit-box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
    box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
}
#scrollUp:focus,
#scrollUp:hover {
    background-color: #000000;
    color: #ffffff;
}
@media only screen and (max-width: 767px) {
    #scrollUp {
        right: 30px;
        bottom: 30px;
    }
}

/* :: 6.0 Buttons CSS */
.uza-btn {
    position: relative;
    z-index: 1;
    min-width: 160px;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    padding: 0 30px;
    text-align: center;
    color: #1583e9;
    border: none;
    border-radius: 50px;
    background-color: #ffffff;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
    box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
}
.uza-btn:focus,
.uza-btn:hover {
    -webkit-box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
    box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
    background-color: #1583e9;
    color: #ffffff;
}
.uza-btn.btn-2 {
    background-color: #1583e9;
    color: #ffffff;
}
.uza-btn.btn-2:focus,
.uza-btn.btn-2:hover {
    background-color: #ffffff;
    color: #1583e9;
}
.uza-btn.btn-3 {
    border: 2px solid #1583e9;
    line-height: 46px;
}

.login-register-btn a {
    display: inline-block;
}
.login-register-btn a span {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    color: #a6a6a6;
}
.login-register-btn a span:focus,
.login-register-btn a span:hover {
    color: #1583e9;
}
.login-register-btn a:focus,
.login-register-btn a:hover {
    color: #1583e9;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .login-register-btn a {
        font-size: 14px;
    }
}

/* :: 7.0 Header CSS */
.header-area {
    position: fixed;
    z-index: 600;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-area .main-header-area {
        border-top: 10px solid #303030;
    }
}
@media only screen and (max-width: 767px) {
    .header-area .main-header-area {
        border-top: 10px solid #303030;
    }
}
.header-area .main-header-area.sticky {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    top: 0;
    width: 100%;
    position: fixed;
    background-color: #e7f2fd;
    -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    border-bottom: none;
    left: 0;
    z-index: 650;
}
.header-area .search-icon {
    position: relative;
    z-index: 1;
    margin-left: 14px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-area .search-icon {
        margin-left: 15px;
        margin-top: 20px;
    }
}
@media only screen and (max-width: 767px) {
    .header-area .search-icon {
        margin-left: 15px;
        margin-top: 20px;
    }
}
.header-area .search-icon i {
    font-size: 20px;
    color: #303030;
    cursor: pointer;
}

.classy-nav-container {
    background-color: transparent;
    padding: 0;
}
@media only screen and (max-width: 767px) {
    .classy-nav-container {
        padding: 0 15px;
    }
}
.classy-nav-container .classy-navbar {
    height: 100px;
    padding: 0 3%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .classy-nav-container .classy-navbar {
        height: 70px;
        padding: 0 5%;
    }
}
@media only screen and (max-width: 767px) {
    .classy-nav-container .classy-navbar {
        height: 70px;
        padding: 0 5%;
    }
}
@media only screen and (max-width: 767px) {
    .classy-nav-container .classy-navbar .nav-brand {
        max-width: 90px;
        margin-right: 15px;
    }
}
.classy-nav-container .classy-navbar .classynav ul li a {
    position: relative;
    z-index: 1;
    font-weight: normal;
    font-size: 18px;
    color: #303030;
}
.classy-nav-container .classy-navbar .classynav ul li a:focus,
.classy-nav-container .classy-navbar .classynav ul li a:hover {
    color: #1583e9;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .classy-nav-container .classy-navbar .classynav ul li a {
        font-size: 14px;
        padding: 0 10px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .classy-nav-container .classy-navbar .classynav ul li a {
        font-size: 14px;
    }
}
@media only screen and (max-width: 767px) {
    .classy-nav-container .classy-navbar .classynav ul li a {
        font-size: 14px;
    }
}
.classy-nav-container .classy-navbar .classynav ul li.cn-dropdown-item ul li a {
    font-size: 16px;
    height: 42px;
    line-height: 42px;
    padding: 0 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .classy-nav-container .classy-navbar .classynav ul li.cn-dropdown-item ul li a {
        font-size: 14px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .classy-nav-container .classy-navbar .classynav ul li.cn-dropdown-item ul li a {
        font-size: 14px;
    }
}
@media only screen and (max-width: 767px) {
    .classy-nav-container .classy-navbar .classynav ul li.cn-dropdown-item ul li a {
        font-size: 14px;
    }
}
.classy-nav-container .classy-navbar .classynav ul li a.active {
    color: #1583e9;
}
.classy-nav-container .classy-navbar .classynav ul li a.active::after {
    content: '';
    position: absolute;
    width: 70%;
    height: 2px;
    background-color: #1583e9;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .classy-nav-container .classy-navbar .classynav ul li a.active::after {
        width: 100%;
        height: 1px;
    }
}
@media only screen and (max-width: 767px) {
    .classy-nav-container .classy-navbar .classynav ul li a.active::after {
        width: 100%;
        height: 1px;
    }
}

.classynav ul li.has-down > a::after,
.classynav ul li.has-down.active > a::after,
.classynav ul li.megamenu-item > a::after {
    color: #303030;
}

.breakpoint-off .classynav ul li .dropdown {
    width: 210px;
    -webkit-box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
    box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
    border-radius: 6px;
}

.top-search-area .modal-content {
    border: none;
}
.top-search-area .modal-content .modal-body {
    padding: 50px;
}
@media only screen and (max-width: 767px) {
    .top-search-area .modal-content .modal-body {
        padding: 50px 15px;
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .top-search-area .modal-content .modal-body {
        padding: 50px;
    }
}
.top-search-area .modal-content .modal-body button.close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 10;
    font-size: 14px;
}
.top-search-area .modal-content .modal-body button.close-btn.btn {
    padding: 0;
    background-color: transparent;
}
.top-search-area .modal-content .modal-body button.close-btn.btn:focus,
.top-search-area .modal-content .modal-body button.close-btn.btn:hover {
    box-shadow: none;
    color: #1583e9;
}
.top-search-area form {
    width: 100%;
    position: relative;
    z-index: 1;
}
.top-search-area form input {
    width: 100%;
    height: 50px;
    border: none;
    box-shadow: 0 0 30px 15px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    font-size: 12px;
    font-style: italic;
    padding: 0 20px;
}
.top-search-area form input:focus {
    box-shadow: 0 0 30px 15px rgba(0, 0, 0, 0.08);
}
.top-search-area form button {
    position: absolute;
    width: 100px;
    height: 50px;
    top: 0;
    right: 0;
    z-index: 10;
    border-radius: 0 6px 6px 0;
    border: none;
    background-color: #1583e9;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}
@media only screen and (max-width: 767px) {
    .top-search-area form button {
        width: 80px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .get-a-quote {
        margin: 30px 15px !important;
    }
}
@media only screen and (max-width: 767px) {
    .get-a-quote {
        margin: 30px 15px !important;
    }
}

/* :: 8.0 Hero CSS */
.welcome-slides {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.single-welcome-slide {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 900px;
    background-color: #ffffff;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-welcome-slide {
        height: 750px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-welcome-slide {
        height: 600px;
    }
}
@media only screen and (max-width: 767px) {
    .single-welcome-slide {
        height: 900px;
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .single-welcome-slide {
        height: 1000px;
    }
}
.single-welcome-slide .background-curve {
    position: absolute;
    width: 70%;
    top: 0;
    height: 100%;
    right: 0;
    z-index: -1;
}
.single-welcome-slide .background-curve img {
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
}
.single-welcome-slide .welcome-text {
    position: relative;
    z-index: 1;
}
@media only screen and (max-width: 767px) {
    .single-welcome-slide .welcome-text {
        padding-top: 50px;
    }
}
.single-welcome-slide .welcome-text h2 {
    font-size: 72px;
    display: block;
    margin-bottom: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-welcome-slide .welcome-text h2 {
        font-size: 48px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-welcome-slide .welcome-text h2 {
        font-size: 42px;
    }
}
@media only screen and (max-width: 767px) {
    .single-welcome-slide .welcome-text h2 {
        font-size: 36px;
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .single-welcome-slide .welcome-text h2 {
        font-size: 42px;
    }
}
.single-welcome-slide .welcome-text h2 span {
    color: #1583e9;
}
.single-welcome-slide .welcome-text h5 {
    font-size: 22px;
    color: #a6a6a6;
    margin-bottom: 60px;
    display: block;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-welcome-slide .welcome-text h5 {
        font-size: 18px;
        margin-bottom: 40px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-welcome-slide .welcome-text h5 {
        font-size: 14px;
        margin-bottom: 40px;
    }
}

/* :: 09.0 Portfolio CSS */
.uza-portfolio-area {
    position: relative;
    z-index: 1;
    padding-left: 3%;
    padding-right: 3%;
}
.uza-portfolio-area .portfolio-bg-curve {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    height: auto;
    width: auto;
}

.single-portfolio-slide {
    position: relative;
    z-index: 1;
    margin-bottom: 50px;
}
.single-portfolio-slide img {
    position: relative;
    z-index: 1;
    width: 100%;
}
.single-portfolio-slide .overlay-effect {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    background-color: rgba(231, 242, 253, 0.9);
    padding: 40px 30px;
    opacity: 0;
    visibility: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-portfolio-slide .overlay-effect {
        padding: 30px;
    }
}
@media only screen and (max-width: 767px) {
    .single-portfolio-slide .overlay-effect {
        padding: 30px;
    }
}
.single-portfolio-slide .overlay-effect h4 {
    display: block;
    margin-bottom: 15px;
    font-weight: 600;
}
@media only screen and (max-width: 767px) {
    .single-portfolio-slide .overlay-effect h4 {
        margin-bottom: 10px;
        font-size: 20px;
    }
}
.single-portfolio-slide .overlay-effect p {
    margin-bottom: 0;
    line-height: 1.6;
}
@media only screen and (max-width: 767px) {
    .single-portfolio-slide .overlay-effect p {
        font-size: 14px;
    }
}
.single-portfolio-slide .view-more-btn a {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #ffffff;
    display: inline-block;
    position: absolute;
    bottom: 40px;
    right: 0;
    z-index: 100;
    text-align: center;
    line-height: 65px;
    font-size: 24px;
    opacity: 0;
    visibility: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-portfolio-slide .view-more-btn a {
        bottom: 30px;
        width: 40px;
        height: 40px;
        line-height: 45px;
    }
}
@media only screen and (max-width: 767px) {
    .single-portfolio-slide .view-more-btn a {
        bottom: 30px;
        width: 40px;
        height: 40px;
        line-height: 45px;
    }
}
.single-portfolio-slide .view-more-btn a:focus,
.single-portfolio-slide .view-more-btn a:hover {
    background-color: #1583e9;
    color: #ffffff;
}
.single-portfolio-slide:focus,
.single-portfolio-slide:hover {
    -webkit-box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
    box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
}
.single-portfolio-slide:focus .overlay-effect,
.single-portfolio-slide:hover .overlay-effect {
    opacity: 1;
    visibility: visible;
}
.single-portfolio-slide:focus .view-more-btn a,
.single-portfolio-slide:hover .view-more-btn a {
    right: 40px;
    opacity: 1;
    visibility: visible;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-portfolio-slide:focus .view-more-btn a,
    .single-portfolio-slide:hover .view-more-btn a {
        right: 30px;
    }
}

.portfolio-sildes {
    position: relative;
    z-index: 1;
}
.portfolio-sildes .owl-dots {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.portfolio-sildes .owl-dots .owl-dot {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8px;
    flex: 0 0 8px;
    max-width: 8px;
    margin: 0 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #e7f2fd;
}
.portfolio-sildes .owl-dots .owl-dot.active {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 38px;
    flex: 0 0 38px;
    max-width: 38px;
    width: 38px;
    border-radius: 15px;
    background-color: #1583e9;
}

.portfolio-menu {
    position: relative;
    z-index: 1;
}
.portfolio-menu button {
    background-color: transparent;
    padding: 0;
    font-weight: 600;
    color: #a6a6a6;
    font-size: 22px;
    margin: 0 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .portfolio-menu button {
        font-size: 20px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .portfolio-menu button {
        font-size: 18px;
    }
}
@media only screen and (max-width: 767px) {
    .portfolio-menu button {
        font-size: 16px;
        margin: 0 10px;
    }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .portfolio-menu button {
        font-size: 14px;
        margin: 0 5px;
    }
}
.portfolio-menu button.active,
.portfolio-menu button:hover {
    color: #303030;
}
.portfolio-menu button:focus {
    box-shadow: none;
}

.single-portfolio-item .single-portfolio-slide {
    margin-right: 10px;
    margin-left: 10px;
}

.portfolio-details-text {
    position: relative;
    z-index: 1;
}
.portfolio-details-text h2 {
    font-size: 36px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .portfolio-details-text h2 {
        font-size: 30px;
    }
}
@media only screen and (max-width: 767px) {
    .portfolio-details-text h2 {
        font-size: 24px;
    }
}
.portfolio-details-text h6 {
    font-size: 14px;
    text-transform: uppercase;
    color: #1583e9;
    letter-spacing: 1px;
    margin-bottom: 20px;
    font-weight: 400;
}
.portfolio-details-text p {
    margin-bottom: 0;
}

.portfolio-meta h6 {
    color: #707070;
    margin-bottom: 10px;
    font-weight: 400;
}
@media only screen and (max-width: 767px) {
    .portfolio-meta {
        margin-top: 50px;
    }
}

.portfolio-social-info p {
    margin-bottom: 0;
    margin-right: 15px;
}
.portfolio-social-info a {
    display: inline-block;
    margin-right: 15px;
    font-size: 16px;
}
.portfolio-social-info a:last-child {
    margin-right: 0;
}
.portfolio-social-info a:hover,
.portfolio-social-info a:focus {
    color: #1583e9;
}

.portfolio-pager {
    position: relative;
    z-index: 1;
}
.portfolio-pager a {
    display: inline-block;
    color: #707070;
}
.portfolio-pager a:nth-child(2) {
    color: #a6a6a6;
}
.portfolio-pager a:hover,
.portfolio-pager a:focus {
    color: #1583e9;
}
@media only screen and (max-width: 767px) {
    .portfolio-pager a {
        font-size: 12px;
    }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .portfolio-pager a {
        font-size: 16px;
    }
}

/* :: 10.0 Testimonial CSS */
.testimonial-slides {
    position: relative;
    z-index: 1;
    padding-left: 10%;
    padding-right: 10%;
}
.testimonial-slides .owl-next,
.testimonial-slides .owl-prev {
    position: absolute;
    top: 50%;
    width: 50px;
    height: auto;
    margin-top: -25px;
    left: 0;
    z-index: 10;
    line-height: 50px;
}
.testimonial-slides .owl-next i,
.testimonial-slides .owl-prev i {
    font-size: 50px;
    color: #b7b7b7;
}
.testimonial-slides .owl-next i:hover,
.testimonial-slides .owl-next i:focus,
.testimonial-slides .owl-prev i:hover,
.testimonial-slides .owl-prev i:focus {
    color: #1583e9;
}
.testimonial-slides .owl-next {
    left: auto;
    right: 0;
}

.border-line {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 1px;
    background-color: #e7f2fd;
}

.single-testimonial-slide {
    position: relative;
    z-index: 1;
}
@media only screen and (max-width: 767px) {
    .single-testimonial-slide {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}
.single-testimonial-slide .testimonial-thumbnail {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 170px;
    flex: 0 0 170px;
    max-width: 170px;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    margin-right: 60px;
}
.single-testimonial-slide .testimonial-thumbnail img {
    border-radius: 50%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-testimonial-slide .testimonial-thumbnail {
        margin-right: 30px;
    }
}
@media only screen and (max-width: 767px) {
    .single-testimonial-slide .testimonial-thumbnail {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 120px;
        flex: 0 0 120px;
        max-width: 120px;
        width: 120px;
        height: 120px;
        margin-right: 0;
        margin-bottom: 50px;
    }
}
.single-testimonial-slide .testimonial-content {
    position: relative;
    z-index: 1;
}
.single-testimonial-slide .testimonial-content h4 {
    font-weight: normal;
    font-style: italic;
    margin-bottom: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-testimonial-slide .testimonial-content h4 {
        font-size: 20px;
    }
}
@media only screen and (max-width: 767px) {
    .single-testimonial-slide .testimonial-content h4 {
        font-size: 18px;
    }
}
.single-testimonial-slide .testimonial-content .ratings {
    position: relative;
    z-index: 1;
    display: block;
    margin-bottom: 10px;
}
.single-testimonial-slide .testimonial-content .ratings i {
    font-size: 16px;
    color: #f3c612;
}
.single-testimonial-slide .testimonial-content .author-info h5 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
    .single-testimonial-slide .testimonial-content .author-info h5 {
        font-size: 14px;
    }
}
.single-testimonial-slide .testimonial-content .author-info h5 span {
    font-size: 14px;
    text-transform: uppercase;
    color: #b7b7b7;
}
@media only screen and (max-width: 767px) {
    .single-testimonial-slide .testimonial-content .author-info h5 span {
        font-size: 12px;
    }
}
.single-testimonial-slide .testimonial-content .quote-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}

/* :: 11.0 Newsletter CSS */
.uza-newsletter-area {
    position: relative;
    z-index: 1;
}
.uza-newsletter-area .border-line {
    width: 100%;
    height: 1px;
    background-color: #e7f2fd;
}
.uza-newsletter-area .nl-content {
    position: relative;
    z-index: 1;
}
.uza-newsletter-area .nl-content h2 {
    font-size: 36px;
    display: block;
    margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .uza-newsletter-area .nl-content h2 {
        font-size: 30px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .uza-newsletter-area .nl-content h2 {
        font-size: 24px;
    }
}
@media only screen and (max-width: 767px) {
    .uza-newsletter-area .nl-content h2 {
        font-size: 24px;
    }
}
.uza-newsletter-area .nl-content p {
    margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .uza-newsletter-area .nl-content p {
        font-size: 14px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .uza-newsletter-area .nl-content p {
        font-size: 12px;
    }
}
.uza-newsletter-area .nl-form form {
    position: relative;
    z-index: 1;
}
.uza-newsletter-area .nl-form form input {
    width: 100%;
    height: 50px;
    border-radius: 50px;
    background-color: #e7f2fd;
    border: none;
    font-size: 16px;
    padding: 0 30px;
}
.uza-newsletter-area .nl-form form button {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    position: absolute;
    padding: 0 30px;
    border-radius: 0 50px 50px 0;
    background-color: #1583e9;
    font-size: 16px;
    color: #ffffff;
    height: 50px;
    top: 0;
    right: 0;
    border: none;
    cursor: pointer;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .uza-newsletter-area .nl-form form button {
        padding: 0 20px;
    }
}
@media only screen and (max-width: 767px) {
    .uza-newsletter-area .nl-form form button {
        padding: 0 20px;
    }
}
.uza-newsletter-area .nl-form form button:hover,
.uza-newsletter-area .nl-form form button:focus {
    background-color: #303030;
}

/* :: 12.0 About CSS */
.about-us-content {
    position: relative;
    z-index: 1;
}
.about-us-content h2 {
    font-size: 48px;
    display: block;
    margin-bottom: 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-us-content h2 {
        font-size: 36px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-us-content h2 {
        font-size: 30px;
    }
}
@media only screen and (max-width: 767px) {
    .about-us-content h2 {
        font-size: 30px;
    }
}

.about-us-thumbnail {
    position: relative;
    z-index: 1;
    width: 100%;
    padding-right: 100px;
}
.about-us-thumbnail img {
    width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-us-thumbnail {
        padding-right: 0;
    }
}
@media only screen and (max-width: 767px) {
    .about-us-thumbnail {
        padding-right: 0;
    }
}

.uza-video-area {
    position: absolute;
    top: 50%;
    right: 65px;
    z-index: 99;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .uza-video-area {
        right: auto;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}
@media only screen and (max-width: 767px) {
    .uza-video-area {
        right: auto;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}
.uza-video-area .video-play-btn {
    display: inline-block;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    background-color: #1583e9;
    color: #ffffff;
    line-height: 70px;
    text-align: center;
    font-size: 24px;
}
.uza-video-area .video-play-btn i {
    padding-left: 5px;
}
.uza-video-area .video-play-btn:focus,
.uza-video-area .video-play-btn:hover {
    box-shadow: none;
    background-color: #1583e9;
}

.uza-about-us-area {
    position: relative;
    z-index: 1;
}
.uza-about-us-area .about-bg-pattern {
    position: absolute;
    width: 40%;
    left: 0;
    bottom: 0;
    z-index: -1;
    height: 100%;
}
.uza-about-us-area .about-bg-pattern img {
    height: 100%;
    width: auto;
}

.uza-video-area .video-play-btn {
    -webkit-transform: scale(0.93);
    -ms-transform: scale(0.93);
    transform: scale(0.93);
}
.uza-video-area .video-play-btn:after {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    top: 0;
    left: 0;
    padding: 0;
    z-index: -1;
    box-shadow: 0 0 0 2px #1583e9;
    opacity: 1;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-animation: sonarEffect 1.3s ease-out 75ms infinite;
    animation: sonarEffect 1.3s ease-out 75ms infinite;
}

@-webkit-keyframes sonarEffect {
    0% {
        opacity: 0.3;
    }
    40% {
        opacity: 0.5;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #3851bc,
            0 0 0 10px rgba(255, 255, 255, 0.5);
    }
    100% {
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #3851bc,
            0 0 0 10px rgba(255, 255, 255, 0.5);
        transform: scale(1.5);
        opacity: 0;
    }
}
@-moz-keyframes sonarEffect {
    0% {
        opacity: 0.3;
    }
    40% {
        opacity: 0.5;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #3851bc,
            0 0 0 10px rgba(255, 255, 255, 0.5);
    }
    100% {
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #3851bc,
            0 0 0 10px rgba(255, 255, 255, 0.5);
        transform: scale(1.5);
        opacity: 0;
    }
}
@keyframes sonarEffect {
    0% {
        opacity: 0.3;
    }
    40% {
        opacity: 0.5;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #3851bc,
            0 0 0 10px rgba(255, 255, 255, 0.5);
    }
    100% {
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #3851bc,
            0 0 0 10px rgba(255, 255, 255, 0.5);
        transform: scale(1.5);
        opacity: 0;
    }
}
.uza-why-choose-us-area {
    position: relative;
    z-index: 1;
}
.uza-why-choose-us-area ul li {
    font-size: 16px;
    color: #707070;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.uza-why-choose-us-area ul li i {
    color: #1583e9;
    margin-right: 15px;
}
.uza-why-choose-us-area ul li:last-child {
    margin-bottom: 0;
}

.about-tab-area {
    position: relative;
    z-index: 1;
}
.about-tab-area .nav-tabs .nav-item {
    margin-bottom: 0;
}
.about-tab-area .nav-tabs .nav-link {
    position: relative;
    z-index: 1;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 0 0 15px 0;
    border: none;
    margin-right: 30px;
    background-color: transparent;
}
@media only screen and (max-width: 767px) {
    .about-tab-area .nav-tabs .nav-link {
        letter-spacing: 1px;
        margin-right: 15px;
        font-size: 14px;
    }
}
.about-tab-area .nav-tabs .nav-link.active:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    background-color: #1583e9;
    width: 100%;
    height: 2px;
}

/* 13.0 Service CSS */
.single-service-area {
    position: relative;
    z-index: 1;
    text-align: center;
}
.single-service-area .service-icon {
    background-color: #e7f2fd;
    width: 145px;
    height: 110px;
    font-size: 42px;
    color: #303030;
    text-align: center;
    line-height: 110px;
    border-radius: 80px 200px 200px 362px;
    margin: 0 auto 30px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}
.single-service-area h5 {
    font-size: 22px;
    display: block;
    margin-bottom: 15px;
    -webkit-transition-duration: 300ms;
    -o-transition-duration: 300ms;
    transition-duration: 300ms;
    font-weight: 600;
}
.single-service-area:focus .service-icon,
.single-service-area:hover .service-icon {
    background-color: #1583e9;
    color: #ffffff;
    -webkit-box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
    box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
}
.single-service-area:focus h5,
.single-service-area:hover h5 {
    color: #1583e9;
}

.cta-testimonial-cfa-area {
    position: relative;
    z-index: 1;
}
.cta-testimonial-cfa-area .cta-testimonial-cfa-bg-curve {
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
    height: 100%;
    z-index: -1;
}

/* :: 14.0 Breadcrumb CSS */
.breadcrumb-area {
    position: relative;
    z-index: 1;
    height: 290px;
    margin-bottom: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .breadcrumb-area {
        height: 220px;
    }
}
@media only screen and (max-width: 767px) {
    .breadcrumb-area {
        height: 200px;
        margin-bottom: 0;
    }
}
.breadcrumb-area .breadcrumb-bg-curve {
    position: absolute;
    width: 80%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-size: cover;
    text-align: right !important;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .breadcrumb-area .breadcrumb-bg-curve {
        width: 90%;
    }
}
.breadcrumb-area .title {
    font-size: 60px;
    margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .breadcrumb-area .title {
        font-size: 42px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .breadcrumb-area .title {
        font-size: 36px;
    }
}
@media only screen and (max-width: 767px) {
    .breadcrumb-area .title {
        font-size: 30px;
    }
}
.breadcrumb-area .breadcumb--con .breadcrumb {
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
}
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item,
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item > a,
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item.active {
    font-size: 18px;
    color: #303030;
    font-weight: normal;
    letter-spacing: normal;
}
@media only screen and (max-width: 767px) {
    .breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item,
    .breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item > a,
    .breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item.active {
        font-size: 14px;
    }
}
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item:focus,
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item:hover,
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item > a:focus,
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item > a:hover,
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item.active:focus,
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item.active:hover {
    color: #1583e9;
}
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item.active {
    color: #a6a6a6;
}
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item.active:focus,
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item.active:hover {
    color: #a6a6a6;
}
.breadcrumb-area .breadcumb--con .breadcrumb .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.breadcrumb-area .breadcumb--con .breadcrumb .page-item:last-child .page-link {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.breadcrumb-area .breadcumb--con .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    content: '\f105';
    font-family: 'FontAwesome';
}

/* :: 15.0 Blog CSS */
.uza-blog-area {
    position: relative;
    z-index: 1;
}
.uza-blog-area .blog-bg-curve {
    position: absolute;
    width: auto;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.single-blog-post {
    position: relative;
    z-index: 1;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    box-shadow: 0 8px 22px 0 rgba(0, 0, 0, 0.1);
}
.single-blog-post .post-content {
    position: relative;
    z-index: 10;
    padding: 40px;
    background-color: #ffffff;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}
.single-blog-post .post-content .post-date {
    font-size: 16px;
    color: #a6a6a6;
    display: block;
    margin-bottom: 10px;
}
.single-blog-post .post-content .post-date span {
    font-size: 36px;
    color: #1583e9;
    margin-right: 5px;
}
.single-blog-post .post-content .post-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
    display: block;
}
.single-blog-post .post-content .post-title:focus,
.single-blog-post .post-content .post-title:hover {
    color: #1583e9;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-blog-post .post-content .post-title {
        font-size: 18px;
    }
}
@media only screen and (max-width: 767px) {
    .single-blog-post .post-content .post-title {
        font-size: 20px;
    }
}
.single-blog-post .post-content .read-more-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.single-blog-post .post-content .read-more-btn i {
    color: #1583e9;
    font-size: 20px;
}
.single-blog-post:focus,
.single-blog-post:hover {
    -webkit-box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
    box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
}
.single-blog-post:focus .post-content,
.single-blog-post:hover .post-content {
    background-color: rgba(255, 255, 255, 0.9);
}

.blog-details-content {
    position: relative;
    z-index: 1;
}
.blog-details-content .post-content .post-date {
    font-size: 16px;
    color: #a6a6a6;
    display: block;
    font-weight: 600;
}
.blog-details-content .post-content .post-date span {
    font-size: 36px;
    color: #1583e9;
}
.blog-details-content .post-content h2 {
    color: #303030;
    font-size: 60px;
    display: block;
    margin-bottom: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog-details-content .post-content h2 {
        font-size: 42px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog-details-content .post-content h2 {
        font-size: 36px;
    }
}
@media only screen and (max-width: 767px) {
    .blog-details-content .post-content h2 {
        font-size: 24px;
    }
}
.blog-details-content h1,
.blog-details-content h2,
.blog-details-content h3,
.blog-details-content h4,
.blog-details-content h5,
.blog-details-content h6 {
    margin-bottom: 15px;
}
@media only screen and (max-width: 767px) {
    .blog-details-content h2 {
        font-size: 24px;
    }
}
.blog-details-content p {
    font-size: 18px;
    color: #a6a6a6;
    font-weight: 400;
}
@media only screen and (max-width: 767px) {
    .blog-details-content p {
        font-size: 16px;
    }
}

.uza-pagination {
    position: relative;
    z-index: 1;
}
.uza-pagination .pagination .page-item .page-link {
    width: 40px;
    height: 40px;
    border-radius: 3px;
    padding: 0;
    line-height: 38px;
    margin: 0 5px;
    text-align: center;
    background-color: transparent;
    color: #707070;
    font-size: 16px;
}
.uza-pagination .pagination .page-item .page-link:focus,
.uza-pagination .pagination .page-item .page-link:hover {
    background-color: #1583e9;
    border-color: #1583e9;
    color: #ffffff;
}
.uza-pagination .pagination .page-item.active .page-link {
    background-color: #1583e9;
    border-color: #1583e9;
    color: #ffffff;
}

.comment_area {
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    padding-top: 50px;
    padding-bottom: 50px;
}
.comment_area .title {
    margin-bottom: 30px;
    font-size: 30px;
}
@media only screen and (max-width: 767px) {
    .comment_area .title {
        font-size: 24px;
    }
}
.comment_area .comment-content {
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
}
.comment_area .comment-content .comment-author {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70px;
    flex: 0 0 70px;
    width: 70px;
    max-width: 70px;
    margin-right: 20px;
    border-radius: 50%;
}
@media only screen and (max-width: 767px) {
    .comment_area .comment-content .comment-author {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50px;
        flex: 0 0 50px;
        width: 50px;
        max-width: 50px;
        margin-right: 10px;
    }
}
.comment_area .comment-content .comment-author img {
    border-radius: 50%;
}
.comment_area .comment-content .comment-meta .post-date {
    color: #1583e9;
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
}
.comment_area .comment-content .comment-meta h5 {
    margin-bottom: 5px;
}
@media only screen and (max-width: 767px) {
    .comment_area .comment-content .comment-meta h5 {
        font-size: 16px;
    }
}
.comment_area .comment-content .comment-meta p {
    color: #303030;
    font-size: 16px;
    font-weight: 500;
}
@media only screen and (max-width: 767px) {
    .comment_area .comment-content .comment-meta p {
        font-size: 14px;
    }
}
.comment_area .comment-content .comment-meta a.like,
.comment_area .comment-content .comment-meta a.reply {
    display: inline-block;
    padding: 5px 15px 3px;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid #ebebeb;
    text-transform: uppercase;
}
.comment_area .comment-content .comment-meta a.like:focus,
.comment_area .comment-content .comment-meta a.like:hover,
.comment_area .comment-content .comment-meta a.reply:focus,
.comment_area .comment-content .comment-meta a.reply:hover {
    border-color: #1583e9;
    background-color: #1583e9;
    color: #ffffff;
}
.comment_area .children {
    margin-left: 50px;
}
@media only screen and (max-width: 767px) {
    .comment_area .children {
        margin-left: 20px;
    }
}
.comment_area .children .single_comment_area:last-of-type {
    margin-bottom: 0;
}

.uza-blockquote {
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    margin-top: 30px;
}
.uza-blockquote .icon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70px;
    flex: 0 0 70px;
    max-width: 70px;
    width: 70px;
}
@media only screen and (max-width: 767px) {
    .uza-blockquote .icon {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50px;
        flex: 0 0 50px;
        max-width: 50px;
        width: 50px;
    }
}
.uza-blockquote .icon i {
    font-size: 60px;
    color: #1583e9;
}
@media only screen and (max-width: 767px) {
    .uza-blockquote .icon i {
        font-size: 30px;
    }
}
.uza-blockquote .text h5 {
    font-size: 30px;
    font-style: italic;
    margin-bottom: 15px;
    line-height: 1.6;
    font-weight: 400;
}
@media only screen and (max-width: 767px) {
    .uza-blockquote .text h5 {
        font-size: 20px;
    }
}
.uza-blockquote .text h6 {
    font-size: 30px;
    margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
    .uza-blockquote .text h6 {
        font-size: 20px;
    }
}

.uza-post-share {
    position: relative;
    z-index: 1;
}
@media only screen and (max-width: 767px) {
    .uza-post-share h6 {
        font-size: 10px;
        margin-right: 10px !important;
    }
}
.uza-post-share .social-info- a {
    display: inline-block;
    color: #303030;
    font-size: 16px;
    margin-right: 15px;
}
.uza-post-share .social-info- a:last-child {
    margin-right: 0;
}
.uza-post-share .social-info- a:focus,
.uza-post-share .social-info- a:hover {
    color: #1583e9;
}
@media only screen and (max-width: 767px) {
    .uza-post-share .social-info- a {
        font-size: 12px;
        margin-right: 5px;
    }
}

.post-catagories {
    position: relative;
    z-index: 1;
    margin: 50px 0;
}
.post-catagories ul li a {
    display: inline-block;
    padding: 8px 20px;
    border: 1px solid #ebebeb;
    background-color: #ffffff;
    color: #303030;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    margin: 3px;
}
.post-catagories ul li a:focus,
.post-catagories ul li a:hover {
    background-color: #1583e9;
    color: #ffffff;
}
@media only screen and (max-width: 767px) {
    .post-catagories ul li a {
        font-size: 10px;
        padding: 5px 10px;
    }
}

.related-news-area {
    position: relative;
    z-index: 1;
    padding-top: 50px;
    border-top: 1px solid #ebebeb;
    margin-bottom: 20px;
}

/* :: 16.0 CTA CSS */
.uza-cta-area {
    position: relative;
    z-index: 1;
}

.cta-content {
    position: relative;
    z-index: 1;
}
.cta-content h2 {
    font-size: 48px;
    margin-bottom: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .cta-content h2 {
        font-size: 36px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cta-content h2 {
        font-size: 42px;
    }
}
@media only screen and (max-width: 767px) {
    .cta-content h2 {
        font-size: 30px;
    }
}
.cta-content h6 {
    font-size: 18px;
    margin-bottom: 0;
    color: #a6a6a6;
}
.cta-content .call-now-btn a {
    display: inline-block;
    font-size: 30px;
    color: #1583e9;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .cta-content .call-now-btn a {
        font-size: 22px;
    }
}
@media only screen and (max-width: 767px) {
    .cta-content .call-now-btn a {
        font-size: 24px;
    }
}
.cta-content .call-now-btn a span {
    font-size: 18px;
    color: #a6a6a6;
}
@media only screen and (max-width: 767px) {
    .cta-content .call-now-btn a span {
        font-size: 14px;
    }
}

/* :: 17.0 CFA CSS */
.single-cf-area {
    position: relative;
    z-index: 1;
}
.single-cf-area h2 {
    margin-bottom: 0;
    font-size: 60px;
    color: #1583e9;
    margin-right: 20px;
}
.single-cf-area .cf-text h6 {
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 600;
}
.single-cf-area .bg-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    font-size: 60px;
    color: #eaeaea;
}

/* :: 18.0 Team CSS */
.single-team-slide {
    position: relative;
    z-index: 1;
    margin-bottom: 50px;
}
.single-team-slide img {
    position: relative;
    z-index: 1;
    width: 100%;
}
.single-team-slide .overlay-effect {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    background-color: rgba(231, 242, 253, 0.9);
    padding: 40px 30px;
    opacity: 0;
    visibility: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-team-slide .overlay-effect {
        padding: 30px;
    }
}
@media only screen and (max-width: 767px) {
    .single-team-slide .overlay-effect {
        padding: 30px;
    }
}
.single-team-slide .overlay-effect h6 {
    font-size: 14px;
    color: #1583e9;
    margin-bottom: 5px;
    text-transform: uppercase;
}
.single-team-slide .overlay-effect h4 {
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
}
@media only screen and (max-width: 767px) {
    .single-team-slide .overlay-effect h4 {
        margin-bottom: 5px;
        font-size: 20px;
    }
}
.single-team-slide .overlay-effect p {
    margin-bottom: 0;
    line-height: 1.6;
}
@media only screen and (max-width: 767px) {
    .single-team-slide .overlay-effect p {
        font-size: 14px;
    }
}
.single-team-slide .team-social-info {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    position: absolute;
    bottom: 40px;
    left: 80px;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-team-slide .team-social-info {
        bottom: 30px;
    }
}
@media only screen and (max-width: 767px) {
    .single-team-slide .team-social-info {
        bottom: 30px;
    }
}
.single-team-slide .team-social-info a {
    display: inline-block;
    color: #ffffff;
    margin-right: 5px;
    background-color: #1583e9;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
}
.single-team-slide .team-social-info a.facebook {
    background-color: #4c60ac;
}
.single-team-slide .team-social-info a.twitter {
    background-color: #00bae3;
}
.single-team-slide .team-social-info a.pinterest {
    background-color: #d91a18;
}
.single-team-slide .team-social-info a.instagram {
    background-color: #0085d7;
}
.single-team-slide .team-social-info a.youtube {
    background-color: #e20000;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-team-slide .team-social-info a {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
    }
}
@media only screen and (max-width: 767px) {
    .single-team-slide .team-social-info a {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
    }
}
.single-team-slide:focus,
.single-team-slide:hover {
    -webkit-box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
    box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
}
.single-team-slide:focus .overlay-effect,
.single-team-slide:hover .overlay-effect {
    opacity: 1;
    visibility: visible;
}
.single-team-slide:focus .team-social-info,
.single-team-slide:hover .team-social-info {
    visibility: visible;
    opacity: 1;
    left: 30px;
}

.team-sildes {
    position: relative;
    z-index: 1;
}
.team-sildes .owl-dots {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.team-sildes .owl-dots .owl-dot {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8px;
    flex: 0 0 8px;
    max-width: 8px;
    margin: 0 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #e7f2fd;
}
.team-sildes .owl-dots .owl-dot.active {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 38px;
    flex: 0 0 38px;
    max-width: 38px;
    width: 38px;
    border-radius: 15px;
    background-color: #1583e9;
}

/* :: 19.0 Footer CSS */
.footer-area {
    position: relative;
    z-index: 1;
}

.single-footer-widget {
    position: relative;
    z-index: 1;
}
.single-footer-widget .widget-title {
    color: #303030;
    margin-bottom: 20px;
    display: block;
    font-weight: 600;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-footer-widget p {
        font-size: 14px;
    }
}
.single-footer-widget .footer-social-info a {
    display: inline-block;
    color: #ffffff;
    margin-right: 5px;
    background-color: #1583e9;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
}
.single-footer-widget .footer-social-info a.facebook {
    background-color: #4c60ac;
}
.single-footer-widget .footer-social-info a.twitter {
    background-color: #00bae3;
}
.single-footer-widget .footer-social-info a.pinterest {
    background-color: #d91a18;
}
.single-footer-widget .footer-social-info a.instagram {
    background-color: #0085d7;
}
.single-footer-widget .footer-social-info a.youtube {
    background-color: #e20000;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-footer-widget .footer-social-info a {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
    }
}
.single-footer-widget .our-link {
    position: relative;
    z-index: 1;
}
.single-footer-widget .our-link li a {
    position: relative;
    z-index: 1;
    font-size: 16px;
    color: #707070;
    margin-bottom: 15px;
    display: block;
    font-weight: normal;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-footer-widget .our-link li a {
        font-size: 14px;
    }
}
.single-footer-widget .our-link li a::after {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
    height: 3px;
    background-color: #1583e9;
    content: '';
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.single-footer-widget .our-link li a:focus,
.single-footer-widget .our-link li a:hover {
    color: #1583e9;
    padding-left: 25px;
}
.single-footer-widget .our-link li a:focus::after,
.single-footer-widget .our-link li a:hover::after {
    width: 15px;
}
.single-footer-widget .our-link li:last-child a {
    margin-bottom: 0;
}
.single-footer-widget .footer-content {
    position: relative;
    z-index: 1;
}
.single-footer-widget .footer-content h3 {
    color: #1583e9;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-footer-widget .footer-content h3 {
        font-size: 20px;
    }
}

.copywrite-text {
    position: relative;
    z-index: 1;
    margin-top: 20px;
}
.copywrite-text p {
    font-size: 16px;
    color: #a6a6a6;
    margin-bottom: 0;
}
.copywrite-text p a {
    color: #a6a6a6;
}
.copywrite-text p a:focus,
.copywrite-text p a:hover {
    color: #1583e9;
}

/* :: 20.0 Contact Area */
.uza-contact-area {
    position: relative;
    z-index: 1;
}
.uza-contact-area .google-maps {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 500px;
}
@media only screen and (max-width: 767px) {
    .uza-contact-area .google-maps {
        height: 280px;
    }
}
.uza-contact-area .google-maps iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.single-contact-card {
    position: relative;
    z-index: 1;
}
.single-contact-card h4 {
    margin-bottom: 15px;
}
.single-contact-card h3 {
    color: #1583e9;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-contact-card h3 {
        font-size: 20px;
    }
}
.single-contact-card h6 {
    margin-bottom: 0;
    color: #707070;
    line-height: 1.5;
    font-weight: 400;
}
@media only screen and (max-width: 767px) {
    .single-contact-card h6 {
        font-size: 14px;
    }
}

.uza-contact-form {
    position: relative;
    z-index: 1;
}
.uza-contact-form .form-control {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    border-radius: 30px;
    color: #a6a6a6;
    font-size: 14px;
}
.uza-contact-form .form-control:focus {
    box-shadow: none;
    border-color: #1583e9;
}
.uza-contact-form textarea.form-control {
    height: 110px;
    padding: 20px;
}
