/* :: 11.0 Newsletter CSS */

.uza-newsletter-area {
    position: relative;
    z-index: 1;
}

.uza-newsletter-area .border-line {
    width: 100%;
    height: 1px;
    background-color: #e7f2fd;
}

.uza-newsletter-area .nl-content {
    position: relative;
    z-index: 1;
}

.uza-newsletter-area .nl-content h2 {
    font-size: 36px;
    display: block;
    margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .uza-newsletter-area .nl-content h2 {
        font-size: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .uza-newsletter-area .nl-content h2 {
        font-size: 24px;
    }
}

@media only screen and (max-width: 767px) {
    .uza-newsletter-area .nl-content h2 {
        font-size: 24px;
    }
}

.uza-newsletter-area .nl-content p {
    margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .uza-newsletter-area .nl-content p {
        font-size: 14px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .uza-newsletter-area .nl-content p {
        font-size: 12px;
    }
}

.uza-newsletter-area .nl-form form {
    position: relative;
    z-index: 1;
}

.uza-newsletter-area .nl-form form input {
    width: 100%;
    height: 50px;
    border-radius: 50px;
    background-color: #e7f2fd;
    border: none;
    font-size: 16px;
    padding: 0 30px;
}

.uza-newsletter-area .nl-form form button {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    position: absolute;
    padding: 0 30px;
    border-radius: 0 50px 50px 0;
    background-color: #1583e9;
    font-size: 16px;
    color: #ffffff;
    height: 50px;
    top: 0;
    right: 0;
    border: none;
    cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .uza-newsletter-area .nl-form form button {
        padding: 0 20px;
    }
}

@media only screen and (max-width: 767px) {
    .uza-newsletter-area .nl-form form button {
        padding: 0 20px;
    }
}

.uza-newsletter-area .nl-form form button:hover,
.uza-newsletter-area .nl-form form button:focus {
    background-color: #303030;
}
