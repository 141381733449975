/* :: 3.0 Preloader CSS */
#preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: #1583e9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.wrapper {
    margin: auto;
    display: block;
}

.cssload-loader {
    width: 49px;
    height: 49px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    background: #ffffff;
}

.cssload-loader,
.cssload-loader:after,
.cssload-loader:before {
    animation: 1.15s infinite ease-in-out;
    -o-animation: 1.15s infinite ease-in-out;
    -ms-animation: 1.15s infinite ease-in-out;
    -webkit-animation: 1.15s infinite ease-in-out;
    -moz-animation: 1.15s infinite ease-in-out;
}

.cssload-loader:after,
.cssload-loader:before {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
}

.cssload-loader {
    animation-name: cssload-loader;
    -o-animation-name: cssload-loader;
    -ms-animation-name: cssload-loader;
    -webkit-animation-name: cssload-loader;
    -moz-animation-name: cssload-loader;
}

@keyframes cssload-loader {
    from {
        transform: scale(0);
        opacity: 1;
    }

    to {
        transform: scale(1);
        opacity: 0;
    }
}

@-o-keyframes cssload-loader {
    from {
        -o-transform: scale(0);
        opacity: 1;
    }

    to {
        -o-transform: scale(1);
        opacity: 0;
    }
}

@-ms-keyframes cssload-loader {
    from {
        -ms-transform: scale(0);
        opacity: 1;
    }

    to {
        -ms-transform: scale(1);
        opacity: 0;
    }
}

@-webkit-keyframes cssload-loader {
    from {
        -webkit-transform: scale(0);
        opacity: 1;
    }

    to {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

@-moz-keyframes cssload-loader {
    from {
        -moz-transform: scale(0);
        opacity: 1;
    }

    to {
        -moz-transform: scale(1);
        opacity: 0;
    }
}
