/* :: 8.0 Hero CSS */

.welcome-slides {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.single-welcome-slide {
    position: relative;
    z-index: 2;
    width: 100%;
    height: auto;
    background-color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-welcome-slide {
        height: 750px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-welcome-slide {
        height: 600px;
    }
}

@media only screen and (max-width: 767px) {
    .single-welcome-slide {
        height: 900px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .single-welcome-slide {
        height: 1000px;
    }
}

.single-welcome-slide .background-curve {
    position: absolute;
    width: 70%;
    top: 0;
    height: 100%;
    right: 0;
    z-index: -1;
}

.single-welcome-slide .background-curve img {
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
}

.single-welcome-slide .welcome-text {
    position: relative;
    z-index: 1;
}

@media only screen and (max-width: 767px) {
    .single-welcome-slide .welcome-text {
        padding-top: 50px;
    }
}

.single-welcome-slide .welcome-text h2 {
    font-size: 41px;
    display: block;
    margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-welcome-slide .welcome-text h2 {
        font-size: 48px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-welcome-slide .welcome-text h2 {
        font-size: 42px;
    }
}

@media only screen and (max-width: 767px) {
    .single-welcome-slide .welcome-text h2 {
        font-size: 36px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .single-welcome-slide .welcome-text h2 {
        font-size: 42px;
    }
}

.single-welcome-slide .welcome-text h2 span {
    color: #1583e9;
}

.single-welcome-slide .welcome-text h5 {
    font-size: 22px;
    color: #a6a6a6;
    margin-bottom: 60px;
    display: block;
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        font-size: 18px;
        margin-bottom: 40px;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        font-size: 14px;
        margin-bottom: 40px;
    }
}
