/* 13.0 Service CSS */

.single-service-area {
    position: relative;
    z-index: 1;
    text-align: center;
}
.single-service-area .service-icon {
    background-color: #e7f2fd;
    width: 145px;
    height: 110px;
    font-size: 42px;
    color: #303030;
    text-align: center;
    line-height: 110px;
    border-radius: 80px 200px 200px 362px;
    margin: 0 auto 30px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.single-service-area h5 {
    font-size: 22px;
    display: block;
    margin-bottom: 15px;
    -webkit-transition-duration: 300ms;
    -o-transition-duration: 300ms;
    transition-duration: 300ms;
    font-weight: 600;
}

.single-service-area:hover .service-icon {
    background-color: #1583e9;
    color: #ffffff;
    box-shadow: (0 6px 50px 8px rgba(21, 131, 233, 0.15));
}

.single-service-area:hover h5 {
    color: #1583e9;
}

.single-service-area:focus .service-icon {
    background-color: #1583e9;
    color: #ffffff;
    box-shadow: (0 6px 50px 8px rgba(21, 131, 233, 0.15));
}

.single-service-area:focus h5 {
    color: #1583e9;
}

.cta-testimonial-cfa-area {
    position: relative;
    z-index: 1;
}

.cta-testimonial-cfa-area .cta-testimonial-cfa-bg-curve {
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
    height: 100%;
    z-index: -1;
}
