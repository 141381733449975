/* :: 18.0 Team CSS */

.single-team-slide {
    position: relative;
    z-index: 1;
    margin-bottom: 50px;
}

.single-team-slide img {
    position: relative;
    z-index: 1;
    width: 100%;
}

.single-team-slide .overlay-effect {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    background-color: rgba(231, 242, 253, 0.9);
    padding: 40px 30px;
    opacity: 0;
    visibility: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-team-slide .overlay-effect {
        padding: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .single-team-slide .overlay-effect {
        padding: 30px;
    }
}

.single-team-slide .overlay-effect h6 {
    font-size: 14px;
    color: #1583e9;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.single-team-slide .overlay-effect h4 {
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
}

@media only screen and (max-width: 767px) {
    .single-team-slide .overlay-effect h4 {
        margin-bottom: 5px;
        font-size: 20px;
    }
}

.single-team-slide .overlay-effect p {
    margin-bottom: 0;
    line-height: 1.6;
}

@media only screen and (max-width: 767px) {
    .single-team-slide .overlay-effect p {
        font-size: 14px;
    }
}

.single-team-slide .team-social-info {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    position: absolute;
    bottom: 40px;
    left: 80px;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-team-slide .team-social-info {
        bottom: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .single-team-slide .team-social-info {
        bottom: 30px;
    }
}

.single-team-slide .team-social-info a {
    display: inline-block;
    color: #ffffff;
    margin-right: 5px;
    background-color: #1583e9;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
}

.single-team-slide .team-social-info a.facebook {
    background-color: #4c60ac;
}

.single-team-slide .team-social-info a.twitter {
    background-color: #00bae3;
}

.single-team-slide .team-social-info a.pinterest {
    background-color: #d91a18;
}

.single-team-slide .team-social-info a.instagram {
    background-color: #0085d7;
}

.single-team-slide .team-social-info a.youtube {
    background-color: #e20000;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-team-slide .team-social-info a {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .single-team-slide .team-social-info a {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
    }
}

.single-team-slide :focus,
.single-team-slide :hover {
    box-shadow: (0 6px 50px 8px rgba(21, 131, 233, 0.15));
}

.single-team-slide :focus .overlay-effect,
.single-team-slide :hover .overlay-effect {
    opacity: 1;
    visibility: visible;
}

.single-team-slide :focus .team-social-info,
.single-team-slide :hover .team-social-info {
    visibility: visible;
    opacity: 1;
    left: 30px;
}

.team-sildes {
    position: relative;
    z-index: 1;
}

.team-sildes .owl-dots {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.team-sildes .owl-dots .owl-dot {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8px;
    flex: 0 0 8px;
    max-width: 8px;
    margin: 0 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #e7f2fd;
}

.team-sildes .owl-dots .owl-dot .active {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 38px;
    flex: 0 0 38px;
    max-width: 38px;
    width: 38px;
    border-radius: 15px;
    background-color: #1583e9;
}
