/* :: 6.0 Buttons CSS */

.uza-btn {
    position: relative;
    z-index: 1;
    min-width: 160px;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    padding: 0 30px;
    text-align: center;
    color: #1583e9;
    border: none;
    border-radius: 50px;
    background-color: #ffffff;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    box-shadow: (0 6px 50px 8px rgba(21, 131, 233, 0.15));
}

.uza-btn:focus,
.uza-btn:hover {
    box-shadow: (0 6px 50px 8px rgba(21, 131, 233, 0.15));
    background-color: #1583e9;
    color: #ffffff;
}

.uza-btn.btn-2 {
    background-color: #1583e9;
    color: #ffffff;
}

.uza-btn.btn-2:focus,
.uza-btn.btn-2:hover {
    background-color: #ffffff;
    color: #1583e9;
}

.uza-btn.btn-3 {
    border: 2px solid #1583e9;
    line-height: 46px;
}

.login-register-btn a {
    display: inline-block;
}

.login-register-btn a span {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    color: #a6a6a6;
}

.login-register-btn a span:focus,
.login-register-btn a span:hover {
    color: #1583e9;
}

.login-register-btn a:focus,
.login-register-btn a:hover {
    color: #1583e9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .login-register-btn a {
        font-size: 14px;
    }
}
