/* :: 17.0 CFA CSS */

.single-cf-area {
    position: relative;
    z-index: 1;
}

.single-cf-area h2 {
    margin-bottom: 0;
    font-size: 60px;
    color: #1583e9;
    margin-right: 20px;
}

.single-cf-area .cf-text h6 {
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 600;
}

.single-cf-area .bg-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    font-size: 60px;
    color: #eaeaea;
}
