.footer-area {
    position: relative;
    z-index: 1;
}

.single-footer-widget {
    position: relative;
    z-index: 1;
}

.single-footer-widget .widget-title {
    color: #303030;
    margin-bottom: 20px;
    display: block;
    font-weight: 600;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-footer-widget p {
        font-size: 14px;
    }
}

.single-footer-widget .footer-social-info a {
    display: inline-block;
    color: #ffffff;
    margin-right: 5px;
    background-color: #1583e9;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
}

.single-footer-widget .footer-social-info a.facebook {
    background-color: #4c60ac;
}

.single-footer-widget .footer-social-info a.twitter {
    background-color: #00bae3;
}

.single-footer-widget .footer-social-info a.pinterest {
    background-color: #d91a18;
}

.single-footer-widget .footer-social-info a.instagram {
    background-color: #0085d7;
}

.single-footer-widget .footer-social-info a.youtube {
    background-color: #e20000;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-footer-widget .footer-social-info a {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
    }
}

.single-footer-widget .our-link {
    position: relative;
    z-index: 1;
}

.single-footer-widget .our-link li a {
    position: relative;
    z-index: 1;
    font-size: 16px;
    color: #707070;
    margin-bottom: 15px;
    display: block;
    font-weight: normal;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-footer-widget .our-link li a {
        font-size: 14px;
    }
}

.single-footer-widget .our-link li a::after {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
    height: 3px;
    background-color: #1583e9;
    content: '';
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.single-footer-widget .our-link li a:focus,
.single-footer-widget .our-link li a:hover {
    color: #1583e9;
    padding-left: 25px;
}

.single-footer-widget .our-link li a:hover::after {
    width: 15px;
}

.single-footer-widget .our-link li a:hover:last-child a {
    margin-bottom: 0;
}

.single-footer-widget .footer-content {
    position: relative;
    z-index: 1;
}

.single-footer-widget .footer-content h3 {
    color: #1583e9;

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        font-size: 20px;
    }
}

.copywrite-text {
    position: relative;
    z-index: 1;
    margin-top: 20px;
}

.copywrite-text p {
    font-size: 16px;
    color: #a6a6a6;
    margin-bottom: 0;
}

.copywrite-text p a {
    color: #a6a6a6;
}

.copywrite-text p a :focus,
.copywrite-text p a :hover {
    color: #1583e9;
}
