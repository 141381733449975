.header-area {
    position: fixed;
    z-index: 600;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-area .main-header-area {
        border-top: 10px solid #303030;
    }
}

@media only screen and (max-width: 767px) {
    .header-area .main-header-area {
        border-top: 10px solid #303030;
    }
    .nav-brand img {
        height: 80px;
    }
}

.header-area .main-header-area.sticky {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    top: 0;
    width: 100%;
    position: fixed;
    background-color: #e7f2fd;
    box-shadow: ((0 1px 6px 0 rgba(0, 0, 0, 0.1)));
    border-bottom: none;
    left: 0;
    z-index: 650;
}
.header-area .search-icon {
    position: relative;
    z-index: 1;
    margin-left: 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-area .search-icon {
        margin-left: 15px;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .header-area .search-icon {
        margin-left: 15px;
        margin-top: 20px;
    }
}

.header-area .search-icon i {
    font-size: 20px;
    color: #303030;
    cursor: pointer;
}

.classy-nav-container {
    background-color: transparent;
    padding: 0;
}

@media only screen and (max-width: 767px) {
    .classy-nav-container {
        padding: 0 15px;
    }
}

.classy-nav-container .classy-navbar {
    height: 120px;
    padding: 0 3%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .classy-nav-container .classy-navbar {
        height: 90px;
        padding: 0 5%;
    }
}

@media only screen and (max-width: 767px) {
    .classy-nav-container .classy-navbar {
        height: 100px;
        padding: 0 5%;
    }
}

@media only screen and (max-width: 767px) {
    .classy-nav-container .classy-navbar .nav-brand {
        max-width: 90px;
        margin-right: 15px;
    }
}

.classy-nav-container .classy-navbar .classynav ul li a {
    position: relative;
    z-index: 1;
    font-weight: normal;
    font-size: 18px;
    color: #303030;
}

.classy-nav-container .classy-navbar .classynav ul li a:focus,
.classy-nav-container .classy-navbar .classynav ul li a:hover {
    color: #1583e9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .classy-nav-container .classy-navbar .classynav ul li a {
        font-size: 14px;
        padding: 0 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .classy-nav-container .classy-navbar .classynav ul li a {
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .classy-nav-container .classy-navbar .classynav ul li a {
        font-size: 14px;
    }
}

.classy-nav-container .classy-navbar .classynav ul li .cn-dropdown-item ul li a {
    font-size: 16px;
    height: 42px;
    line-height: 42px;
    padding: 0 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .classy-nav-container .classy-navbar .classynav ul li .cn-dropdown-item ul li a {
        font-size: 14px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .classy-nav-container .classy-navbar .classynav ul li .cn-dropdown-item ul li a {
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .classy-nav-container .classy-navbar .classynav ul li .cn-dropdown-item ul li a {
        font-size: 14px;
    }
}

.classy-nav-container .classy-navbar .classynav ul li .current-item a::after {
    content: '';
    position: absolute;
    width: 70%;
    height: 2px;
    background-color: #1583e9;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .classy-nav-container .classy-navbar .classynav ul li .current-item a::after {
        width: 100%;
        height: 1px;
    }
    .nav-brand img {
        height: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .classy-nav-container .classy-navbar .classynav ul li .current-item a::after {
        width: 100%;
        height: 1px;
    }
}

.classynav ul li.has-down > a::after,
.classynav ul li.has-down.active > a::after,
.classynav ul li.megamenu-item > a::after {
    color: #303030;
}

.breakpoint-off .classynav ul li .dropdown {
    width: 210px;
    box-shadow: (0 6px 50px 8px rgba(21, 131, 233, 0.15));
    border-radius: 6px;
}

.top-search-area .modal-content {
    border: none;
}

.top-search-area .modal-content .modal-body {
    padding: 50px;
}

@media only screen and (max-width: 767px) {
    .top-search-area .modal-content .modal-body {
        padding: 50px 15px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .top-search-area .modal-content .modal-body {
        padding: 50px;
    }
}

.top-search-area .modal-content .modal-body button.close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 10;
    font-size: 14px;
}

.top-search-area .modal-content .modal-body button.close-btn .btn {
    padding: 0;
    background-color: transparent;
}

.top-search-area .modal-content .modal-body button.close-btn .btn:focus,
.top-search-area .modal-content .modal-body button.close-btn .btn:hover {
    box-shadow: none;
    color: #1583e9;
}

.top-search-area form {
    width: 100%;
    position: relative;
    z-index: 1;
}
.top-search-area form input {
    width: 100%;
    height: 50px;
    border: none;
    box-shadow: 0 0 30px 15px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    font-size: 12px;
    font-style: italic;
    padding: 0 20px;
}

.top-search-area form input:focus {
    box-shadow: 0 0 30px 15px rgba(0, 0, 0, 0.08);
}

.top-search-area form button {
    position: absolute;
    width: 100px;
    height: 50px;
    top: 0;
    right: 0;
    z-index: 10;
    border-radius: 0 6px 6px 0;
    border: none;
    background-color: #1583e9;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

@media only screen and (max-width: 767px) {
    .top-search-area form button {
        width: 80px;
    }
}

.get-a-quote {
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        margin: 30px 15px !important;
    }

    @media only screen and (max-width: 767px) {
        margin: 30px 15px !important;
    }
}
