/* :: 20.0 Contact Area */

.uza-contact-area {
    position: relative;
    z-index: 1;
}

.uza-contact-area .google-maps {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 500px;
}

@media only screen and (max-width: 767px) {
    .uza-contact-area .google-maps {
        height: 280px;
    }
}

.uza-contact-area .google-maps iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.single-contact-card {
    position: relative;
    z-index: 1;
}

.single-contact-card h4 {
    margin-bottom: 15px;
}

.single-contact-card h3 {
    color: #1583e9;

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        font-size: 20px;
    }
}

.single-contact-card h6 {
    margin-bottom: 0;
    color: #707070;
    line-height: 1.5;
    font-weight: 400;
}

@media only screen and (max-width: 767px) {
    .single-contact-card h6 {
        font-size: 14px;
    }
}

.uza-contact-form {
    position: relative;
    z-index: 1;
}

.uza-contact-form .form-control {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    border-radius: 30px;
    color: #a6a6a6;
    font-size: 14px;
}

.uza-contact-form .form-control:focus {
    box-shadow: none;
    border-color: #1583e9;
}

.uza-contact-form textarea.form-control {
    height: 110px;
    padding: 20px;
}
