/* :: 5.0 Backtotop CSS */
#scrollUp {
    bottom: 50px;
    font-size: 30px;
    line-height: 45px;
    right: 50px;
    width: 50px;
    background-color: #1583e9;
    color: #ffffff;
    text-align: center;
    height: 50px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    border-radius: 50%;
    box-shadow: (0 6px 50px 8px rgba(21, 131, 233, 0.15));
}

#scrollUp:focus,
#scrollUp:hover {
    background-color: #000000;
    color: #ffffff;
}

@media only screen and (max-width: 767px) {
    #scrollUp {
        right: 30px;
        bottom: 30px;
    }
}
