/* :: 10.0 Testimonial CSS */

.testimonial-slides {
    position: relative;
    z-index: 1;
    padding-left: 10%;
    padding-right: 10%;
}

.testimonial-slides .owl-next,
.testimonial-slides .owl-prev {
    position: absolute;
    top: 50%;
    width: 50px;
    height: auto;
    margin-top: -25px;
    left: 0;
    z-index: 10;
    line-height: 50px;
}

.testimonial-slides .owl-next i,
.testimonial-slides .owl-prev i {
    font-size: 50px;
    color: #b7b7b7;
}

.testimonial-slides .owl-next i:hover,
.testimonial-slides .owl-prev i:hover,
.testimonial-slides .owl-next i:focus,
.testimonial-slides .owl-prev i:focus {
    color: #1583e9;
}

.testimonial-slides .owl-next {
    left: auto;
    right: 0;
}

.border-line {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 1px;
    background-color: #e7f2fd;
}

.single-testimonial-slide {
    position: relative;
    z-index: 1;
}

@media only screen and (max-width: 767px) {
    .single-testimonial-slide {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.single-testimonial-slide .testimonial-thumbnail {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 170px;
    flex: 0 0 170px;
    max-width: 170px;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    margin-right: 60px;
}

.single-testimonial-slide .testimonial-thumbnail img {
    border-radius: 50%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-testimonial-slide .testimonial-thumbnail {
        margin-right: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .single-testimonial-slide .testimonial-thumbnail {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 120px;
        flex: 0 0 120px;
        max-width: 120px;
        width: 120px;
        height: 120px;
        margin-right: 0;
        margin-bottom: 50px;
    }
}

.single-testimonial-slide .testimonial-content {
    position: relative;
    z-index: 1;
}

.single-testimonial-slide .testimonial-content h4 {
    font-weight: normal;
    font-style: italic;
    margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-testimonial-slide .testimonial-content h4 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .single-testimonial-slide .testimonial-content h4 {
        font-size: 18px;
    }
}

.single-testimonial-slide .testimonial-content .ratings {
    position: relative;
    z-index: 1;
    display: block;
    margin-bottom: 10px;
}

.single-testimonial-slide .testimonial-content .ratings i {
    font-size: 16px;
    color: #f3c612;
}

.single-testimonial-slide .testimonial-content .author-info h5 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
    .single-testimonial-slide .testimonial-content .author-info h5 {
        font-size: 14px;
    }
}

.single-testimonial-slide .testimonial-content .author-info h5 span {
    font-size: 14px;
    text-transform: uppercase;
    color: #b7b7b7;
}

@media only screen and (max-width: 767px) {
    .single-testimonial-slide .testimonial-content .author-info h5 span {
        font-size: 12px;
    }
}

.single-testimonial-slide .testimonial-content .quote-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}
