/* :: 16.0 CTA CSS */

.uza-cta-area {
    position: relative;
    z-index: 1;
}

.cta-content {
    position: relative;
    z-index: 1;
}

.cta-content h2 {
    font-size: 48px;
    margin-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .cta-content h2 {
        font-size: 36px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cta-content h2 {
        font-size: 42px;
    }
}

@media only screen and (max-width: 767px) {
    .cta-content h2 {
        font-size: 30px;
    }
}

.cta-content h6 {
    font-size: 18px;
    margin-bottom: 0;
    color: #a6a6a6;
}

.cta-content .call-now-btn a {
    display: inline-block;
    font-size: 30px;
    color: #1583e9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .cta-content .call-now-btn a {
        font-size: 22px;
    }
}

@media only screen and (max-width: 767px) {
    .cta-content .call-now-btn a {
        font-size: 24px;
    }
}

.cta-content .call-now-btn a span {
    font-size: 18px;
    color: #a6a6a6;
}

@media only screen and (max-width: 767px) {
    .cta-content .call-now-btn a span {
        font-size: 14px;
    }
}
