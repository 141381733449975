.section-heading {
    position: relative;
    z-index: 1;
    margin-bottom: 70px;
}

.section-heading h2 {
    font-size: 48px;
    margin-bottom: 0;
    line-height: 1.1;
}

.section-heading p {
    margin-top: 15px;
    font-size: 18px;
    margin-bottom: 0;
    color: #a6a6a6;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-heading {
        font-size: 36px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-heading {
        font-size: 42px;
    }
}

@media only screen and (max-width: 767px) {
    .section-heading {
        font-size: 30px;
    }
}
